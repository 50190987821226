import React, { useEffect, useState } from "react";
import "./datatable.css";
import "./adminlogistics.css";
import {
  BsArrowRightCircleFill,
  BsCalendar2Check,
  BsPrinterFill,
  BsCarFrontFill,
} from "react-icons/bs";
import {
  formatDate,
  gotoAdminPage,
  showPassengers,
} from "../../service/general";
import {
  MdClearAll,
  MdContentCopy,
  MdEditSquare,
  MdStart,
} from "react-icons/md";
import NewJourney from "./newjourney.js";
import { fetchJourney, journeyStatusUpdate } from "../../service/journey.js";
import { FaPeopleGroup } from "react-icons/fa6";
import { fetchRoutes, fetchUniqueRoutes } from "../../service/routes.js";

function TableRow({ booking, onView, onUpdate, isSelected }) {
  return (
    <tr className={isSelected ? "selected-row" : ""}>
      <td>
        <div className="person-profile">
          <h3>{booking.driversDetails.fullname}</h3>
          <p>
            {booking.driversDetails.phone}{" "}
            <MdContentCopy
              className="phoneCopy"
              onClick={() =>
                navigator.clipboard.writeText(booking.driversDetails.phone)
              }
            ></MdContentCopy>
          </p>
        </div>
      </td>
      <td>
        <div>
          <p
            style={{
              fontSize: "15px",
              marginBottom: "10px",
              display: "flex",
              columnGap: "5px",
            }}
          >
            <b>{booking.from}</b>
            <BsArrowRightCircleFill></BsArrowRightCircleFill>
            <b>{booking.destination}</b>
          </p>
          <p style={{ display: "flex", columnGap: "5px", fontSize: "12px" }}>
            <BsCalendar2Check></BsCalendar2Check>
            <b>
              {formatDate(booking.departureDate)} {booking.departureTime}
            </b>
          </p>
        </div>
      </td>
      <td>
        <div>
          <p
            style={{ display: "flex", alignItems: "center", columnGap: "10px" }}
          >
            <BsCarFrontFill></BsCarFrontFill> {booking.vehicleNumber} (
            {booking.vehicleType})
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
              marginTop: "10px",
            }}
          >
            Status: <b>{booking.status}</b>
            <MdStart></MdStart>
          </p>
        </div>
      </td>
      <td className="admin-action">
        <div className="admin-action-main">
          <FaPeopleGroup
            style={{ cursor: "pointer" }}
            onClick={() => showPassengers(booking.uid)}
          ></FaPeopleGroup>
          <MdEditSquare
            onClick={() => onUpdate(booking)}
            style={{ cursor: "pointer", marginLeft: "5px" }}
          ></MdEditSquare>
          <BsPrinterFill
            style={{ cursor: "pointer", marginLeft: "5px" }}
          ></BsPrinterFill>
        </div>
      </td>
    </tr>
  );
}

function JourneyInventory() {
  const [bookings, bookingsChanged] = useState([
    {
      uid: "",
      vehicleNumber: "",
      licenceNumber: "",
      from: "",
      destination: "",
      createdDate: new Date(),
      departureDate: new Date(),
      departureTime: "",
      vehicleType: "",
      status: "",
      driversDetails: {},
      reviewRating: {},
    },
  ]);
  const [updateMode, setUpdateMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isNewBooking, setIsNewBooking] = useState(false);
  const [filterStatus, setFilterStatus] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
    const [routes, setRoutes] = useState([]);
    const [routesMain, setRoutesMain] = useState([]);

  const filteredData = bookings.filter((booking) => {
    try {
      const matchesSearchTerm =
        booking.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.driverName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.from.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.destination.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.driverName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.phone.includes(searchTerm);
      const matchesStatus =
        filterStatus === "all" || booking.status.toLowerCase() === filterStatus;

      const matchesDate =
        (!startDate || new Date(booking.date) >= new Date(startDate)) &&
        (!endDate || new Date(booking.date) <= new Date(endDate));

      return matchesSearchTerm && matchesStatus && matchesDate;
    } catch {
      return true;
    }
  });

  // Handle viewing booking details
  const handleView = (booking) => {
    var model =
      selectedBooking === null || selectedBooking.uid !== booking.uid
        ? booking
        : null;
    setSelectedBooking(model);
    setUpdateMode(false);
  };
  const handleUpdate = (booking) => {
    handleView(booking);
    setUpdateMode(true);
  };

  // Handle deleting a booking
  const handleDelete = (id) => {
    const updatedData = bookings.filter((booking) => booking.id !== id);
    bookingsChanged(updatedData);
    alert("Booking deleted successfully!");
  };

  // Handle new booking form toggle
  const handleNewBooking = () => {
    setIsNewBooking(true);
    setSelectedBooking(null);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const handleDateSubmit = () => {
    console.log("Date filter applied:", startDate, endDate);
  };

  const handleTodayClick = () => {
    const today = new Date().toISOString().split("T")[0];
    setStartDate(today);
    setEndDate(today);
  };
  const handleDateClear = () => {
    setStartDate("");
    setEndDate("");
  };
  useEffect(() => {
    const fetchTripDetails = async () => {
      var data = await fetchJourney();
      bookingsChanged(data);
    };
    const getRoutes = async () => {
      const fetchedRoutes = await fetchRoutes();
      const from = fetchUniqueRoutes(fetchedRoutes);
      setRoutes(from);
      setRoutesMain(fetchedRoutes);
    };
        getRoutes();
    fetchTripDetails();
  }, []);

  return (
    <div className="invoice-table">
      <SearchFilter
        setSearchTerm={setSearchTerm}
        onNewBooking={handleNewBooking}
        setFilterStatus={setFilterStatus}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        handleDateSubmit={handleDateSubmit}
        handleTodayClick={handleTodayClick}
        handleDateClear={handleDateClear}
        startDate={startDate}
        endDate={endDate}
      />
      <table>
        <thead>
          <tr>
            <th>Driver Details</th>
            <th>Trip Details</th>
            <th>Trip Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((booking, index) => (
            <React.Fragment key={index}>
              <TableRow
                booking={booking}
                onView={handleView}
                onDelete={handleDelete}
                onUpdate={handleUpdate}
                isSelected={
                  selectedBooking !== null &&
                  selectedBooking.uid === booking.uid
                }
              />
              {selectedBooking !== null &&
                selectedBooking.uid === booking.uid && (
                  <tr>
                    <td colSpan="10">
                      <TableRowDetails
                        booking={booking}
                        updateMode={updateMode}
                      />
                    </td>
                  </tr>
                )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {isNewBooking && (
        <NewJourney
          show={isNewBooking}
          onClose={() => setIsNewBooking(false)}
        />
      )}
    </div>
  );
}

function SearchFilter({
  setSearchTerm,
  onNewBooking,
  setFilterStatus,
  handleStartDateChange,
  handleEndDateChange,
  handleDateSubmit,
  handleDateClear,
  startDate,
  endDate,
}) {
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="heaader">
      <h2>Journey History</h2>
      <div className="search-filter">
        <div className="search">
          <input
            type="text"
            placeholder="Search"
            className="search-input"
            onChange={handleSearchChange}
          />
          <input
            type="date"
            className="date-input"
            value={startDate}
            onChange={handleStartDateChange}
          />
          <input
            type="date"
            className="date-input"
            value={endDate}
            onChange={handleEndDateChange}
          />
          <button
            className="admin-clear-btn"
            onClick={handleDateClear}
            title="Clear filter"
          >
            <MdClearAll></MdClearAll>
          </button>
          <button className="submit-btn" onClick={handleDateSubmit}>
            Submit
          </button>

          <button className="new-invoice-btn" onClick={onNewBooking}>
            New Journey
          </button>
        </div>
        <div className="filters">
          <button className="active" onClick={() => setFilterStatus("all")}>
            All Order
          </button>
          <button onClick={() => setFilterStatus("on transit")}>
            On-Transit
          </button>
          <button onClick={() => setFilterStatus("arrived")}>Arrived</button>
        </div>
      </div>
    </div>
  );
}

function TableRowDetails({ booking }) {
  const [data, setData] = useState(booking);
  const updateStatus = async (data) => {
    await journeyStatusUpdate(data);
    gotoAdminPage();
  };
  return (
    <div className="admin-datatable-main">
      <div className="admin-datatable-header">
        <h3 h3>Journey Status</h3>
      </div>
      <br></br>
      <div className="admin-datatable-details">
        <div>
          <select
            className="modal-input"
            value={data.status}
            onChange={(e) => setData({ ...data, status: e.target.value })}
          >
            <option>--Select Journey Status--</option>
            <option>Awaiting</option>
            <option>On-Transit</option>
            <option>Arrived</option>
            <option>Cancelled</option>
          </select>
          <br></br>
          <br></br>
          <button
            className="admin-datatable-header-button"
            onClick={() => updateStatus(data)}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
}

export default JourneyInventory;
