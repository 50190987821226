import React, { useEffect, useState } from "react";
import "../services/logistics.css";
import { createstorage } from "../../service/logistics";
import { getCurrentUser } from "../../service/users";

const Storagecomp = () => {
  const pickup = "--Pickup Location--";
  const [pickuplocation, pickuplocationChanged] = useState(
    "--Select Pickup Location--"
  );
  const [formData, formDataChanged] = useState({
    uid: String,
    fullname: String,
    email: String,
    phone: String,
    ownership: String,
    duration: Number,
    period:'-',
    itemDescription: String,
    itemWeightInKg: Number,
    createdDate: Date,
    dropOffDate: Date,
    RetrievalDate: Date,
    deliveryLocation: String,
    pickUpLocation: String,
    fare: Number,
    isOnlineBooking: Boolean,
    review: String,
    rating: String,
    attendant: String,
    status: String,
    bookingRef: String,
    imageLoc: String,
  });
  const bookLogistic = async () => {
    let result = await createstorage(formData);
    if (result.status === 200) {
      alert(result.data);
      formDataChanged({
        uid: String,
        fullname: String,
        email: String,
        phone: String,
        period: String,
        duration: Number,
        itemDescription: String,
        itemWeightInKg: Number,
        createdDate: Date,
        dropOffDate: Date,
        RetrievalDate: Date,
        deliveryLocation: String,
        pickUpLocation: String,
        fare: Number,
        isOnlineBooking: Boolean,
        review: String,
        rating: String,
        attendant: String,
        status: String,
        bookingRef: String,
        imageLoc: String,
      });
    } else {
      alert(result.data);
    }
  };

  useEffect(() => {
    const refreshUser = async () => {
      const data =await getCurrentUser(true);
      formDataChanged({
        ...formData,
        email: data.email,
        phone: data.phone,
        fullname: `${data.firstname} ${data.lastname}`,
      });
    };
    refreshUser();
  }, []);
  return (
    <div className="right-section">
      <div className="request-form">
        <div className="logistic-header">
          <h4>Item Description</h4>
        </div>
        <div className="logistics-flex">
          <div className="form-group">
            <input
              type="text"
              value={formData.itemDescription}
              placeholder="Tell us about your item"
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  itemDescription: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <input
              type="number"
              placeholder="Item Weight (Kg)"
              value={formData.itemWeightInKg}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  itemWeightInKg: e.target.value,
                })
              }
            />
          </div>
          <div
            className="form-group"
            style={{ gridColumnStart: 1, gridColumnEnd: 3 }}
          >
            <p
              style={{ color: "black", fontSize: "13px", marginBottom: "10px" }}
            >
              Upload item image
            </p>
            <input
              type="file"
              placeholder=""
              value={formData.imageLoc}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  imageLoc: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="logistic-header">
          <h4>Storage Details</h4>
        </div>
        <div className="logistics-flex">
          <div className="form-group">
            <select
              value={formData.period}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  period: e.target.value,
                })
              }
            >
              <option>Estimated Storage Period</option>
              <option>Days</option>
              <option>Weeks</option>
              <option>Months</option>
              <option>Years</option>
            </select>
          </div>
          <div className="form-group">
            <select
              value={pickuplocation}
              onChange={(chg) => pickuplocationChanged(chg.target.value)}
            >
              <option>{pickup}</option>
              <option>Pick-Up from My Home</option>
              <option>Drop-Off at Company Location</option>
            </select>
          </div>

          <div className="form-group">
            <input
              type="number"
              value={formData.duration}
              placeholder={`Duration (${formData.period})`}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  duration: e.target.value,
                })
              }
            />
          </div>

          {pickuplocation === "Pick-Up from My Home" ? (
            <div className="form-group">
              <input
                type="text"
                placeholder="Pick up Address"
                onChange={(e) =>
                  formDataChanged({
                    ...formData,
                    pickUpLocation: e.target.value,
                  })
                }
              />
            </div>
          ) : (
            <div className="form-group">
              <select
                value={formData.pickUpLocation}
                onChange={(e) =>
                  formDataChanged({
                    ...formData,
                    pickUpLocation: e.target.value,
                  })
                }
              >
                <option>--Select Location--</option>
                <option>Lagos</option>
                <option>Benue</option>
              </select>
            </div>
          )}
        </div>
        <div className="logistic-header">
          <h4>Customer Details</h4>
        </div>
        <div className="logistics-flex">
          <div className="form-group">
            <input
              type="text"
              placeholder="Fullname"
              value={formData.fullname}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  fullname: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              value={formData.phone}
              placeholder="Phone Number"
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  phone: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <input
              type="txt"
              placeholder="Email Address"
              value={formData.email}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  email: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <select
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  ownership: e.target.value,
                })
              }
            >
              <option>Do you own the Item?</option>
              <option>Yes</option>
              <option>No (by-proxy)</option>
            </select>
          </div>
        </div>
        <button className="submit-button" onClick={() => bookLogistic()}>
          Submit request
        </button>
      </div>
    </div>
  );
};

export default Storagecomp;
