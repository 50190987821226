import React from 'react';
import './login.css';
import '../booking/bookingcomp.css';




const Validation = () => {
 


  return (
    <div className="header-container-main-2 " style={{ width: '90%' }}>
    <p className="trip-detail-header">Enter your reference code</p>
    <input placeholder="Reference Code" />
    <button className="scheduleButton">Check Status</button>
  </div>
    
  );
};

export default Validation;