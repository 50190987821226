import React from 'react';
import { Navigate } from 'react-router-dom';

const AdminProtectedRoute = ({ children }) => {
  const userRole = localStorage.getItem('role'); // Get the role from local storage
  console.log("from Admin protected route function service res: ", userRole)


  if (userRole !== 'admin') {
    // Redirect to login or an unauthorized page if the user is not an admin
    return <Navigate to="/login" />;
  }
  return children;
};

export default AdminProtectedRoute;