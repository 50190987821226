import React from "react";
import "./logistics.css";
import DashboardHeader from "../dashboard/dashboardheader";
import Footer from "../footer/footer";
import SlideShow from "./slideshow";
import Logisticcomp from "../booking/logisticcomp";
const Logistics = () => {
  return (
    <div>
      <DashboardHeader></DashboardHeader>
      <div className="storagee-container">
        <div className="storage-content">
          <h1>TRAMAS COURIER AND LOGISTICS</h1>
          <p>
            Your trusted partner for top-tier courier and logistics services,
            specializing in the swift and secure delivery of goods, groceries,
            pharmaceuticals, and general commodities.
          </p>

          <div className="storage-buttons">
            <button className="cal-btn">CALL FOR MORE INFO</button>
          </div>
        </div>
      </div>
      <SlideShow></SlideShow>
      <div className="logistics-page">
        <div className="left-section">
          <h2>In need of our Courier Services?</h2>
          <p>Place Your Request for Logistics or Storage Services</p>
          <p>
            It only takes 30 seconds and then we’ll call you back, Monday to
            Friday, 9am-5pm. Easy.
          </p>
          <p>Or Contact Us at 123-45-67</p>
        </div>
        <Logisticcomp></Logisticcomp>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default Logistics;
