// apiService.js
import axios from "axios";
import { ROOT_URL } from "./base";
import { checkUnauthorisedError } from "./general";
import { getAdmin, getAuthHeaders } from "./users";
const BASE_URL = `${ROOT_URL}/journey`;


export const fetchVehicles = async () => {
    try {
      var response = await axios.post(`${BASE_URL}/fetch-all-vehicles`,getAdmin(), {
        headers: getAuthHeaders(),
      });
      return response.data;
    } catch (error){
      return checkUnauthorisedError(error, []);
    }
  };
  
  export const createVehicle = async (reqData) => {
    try {
      const response = await axios.post(`${BASE_URL}/create-vehicle`, reqData,{
          headers: getAuthHeaders(),
        });
      return response;
    } catch (error) {
      console.log(error)
      return checkUnauthorisedError(error, {
        status:400,
        data:'Error Occurred while booking record'
      });
    }
    
  };
  export const updateVehicle = async (reqData) => {
    try {
      const response = await axios.post(`${BASE_URL}/update-vehicle`, reqData,{
          headers: getAuthHeaders(),
        });
      return response;
    } catch (error) {
      console.log(error)
      return checkUnauthorisedError(error, {
        status:400,
        data:'Error Occurred while booking record'
      });
    }
    
  };

export const fetchJourney = async () => {
  try {
    var response = await axios.post(`${BASE_URL}/fetch-all-journey`,getAdmin(), {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error){
    return checkUnauthorisedError(error, []);
  }
};

export const createJourney = async (reqData) => {
  try {
    const response = await axios.post(`${BASE_URL}/create-journey`, reqData,{
        headers: getAuthHeaders(),
      });
    return response;
  } catch (error) {
    console.log(error)
    return checkUnauthorisedError(error, {
      status:400,
      data:'Error Occurred while booking record'
    });
  }
  
};


export const journeyStatusUpdate = async (reqData) => {
    try {
      const response = await axios.post(`${BASE_URL}/status-update`,reqData,{
        headers: getAuthHeaders(),
      });
      return response;
    } catch (error) {
      return checkUnauthorisedError(error, {
        status:400,
        data:'Error Occurred while booking record'
      });
    }
  };
