import React, { useState } from "react";
import "./dashboardheader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const DashboardHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showServicesDropdown, setShowServicesDropdown] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleServiceClick = () => {
    navigate("/");
    setTimeout(() => {
      const serviceSection = document.getElementById("service");
      if (serviceSection) {
        serviceSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 0); // Delay the scroll to ensure the page has loaded
  };

  const handleContactClick = () => {
    navigate("/");
    setTimeout(() => {
      const serviceSection = document.getElementById("contact");
      if (serviceSection) {
        serviceSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 0); // Delay the scroll to ensure the page has loaded
  };
  const toggleServicesDropdown = () => {
    setShowServicesDropdown(!showServicesDropdown);
  };

  return (
    <div className="header-bottom">
      <div className="logo" style={{cursor:'pointer'}} onClick={()=>navigate("/")}>
        <img src={require("../../images/logo1.png")} alt="Transpi" />
      </div>
      <nav className={`nav ${isOpen ? "open" : ""}`}>
        <ul>
          <li>
            <a href="/">HOME</a>
          </li>
          <li>
            <a href="/About">ABOUT</a>
          </li>
          <li
            className="services-dropdown"
            onMouseEnter={toggleServicesDropdown}
            onMouseLeave={toggleServicesDropdown}
          >
            <a onClick={handleServiceClick}>
              SERVICES <FontAwesomeIcon icon={faChevronDown} />
            </a>
            {showServicesDropdown && (
              <ul className="dropdown">
                <li>
                  <a href="/">TRANSPORT SERVICES</a>
                </li>
                <li>
                  <a href="/logistics">COURIER AND LOGISTICS</a>
                </li>
                <li>
                  <a href="/storage">MOVING AND STORAGE</a>
                </li>
              </ul>
            )}
          </li>
          <li>
            <a onClick={handleContactClick}>CONTACT</a>
          </li>
        </ul>
      </nav>
      <button className="hamburger" onClick={toggleMenu}>
        {isOpen ? <FontAwesomeIcon icon={faTimes} /> : "☰"}
      </button>
    </div>
  );
};

export default DashboardHeader;
