import React, { useEffect, useState } from "react";
import "./userprofile.css";
import { fetchProfile, updateProfile } from "../../service/users";

const Profile = () => {
  const [profilePicture, setProfilePicture] = useState(
    "https://via.placeholder.com/100"
  );
  const [isEditable, setIsEditable] = useState(false); // State to toggle between edit and read-only
  const [profileData, setProfileData] = useState({
    fullName: "John Doe",
    email: "john@example.com",
    phoneNumber: "(239) 816-9029",
    gender: "Male",
    nextOfKinName: "Jane Doe",
    nextOfKinPhone: "(239) 816-9029",
    address: "Bay Area, Lagos State",
  });
  useEffect(() => {
    const loadUser = async () => {
      var user = await fetchProfile(localStorage.email);
      setProfileData(user);
    };
    loadUser();
  }, []);

  // Handle image upload
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfilePicture(imageUrl);
    }
  };

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleSaveClick = () => {
    setIsEditable(false);
    updateProfile(profileData)
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value }); // Update profile data
  };

  return (
    <div className="user-profile-container">
      <div className="profile-header">
        <h3>User Profile</h3>
        <span className="breadcrumb">User Profile</span>
      </div>

      <div className="profile-content">
        <div className="left-column">
          <div className="profile-card">
            <img src={profilePicture} alt="User" className="profile-picture" />
            <h2 >{`${profileData.firstname} ${profileData.lastname}`}</h2>
            <br></br>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="upload-input"
            />
          </div>
        </div>

        <div className="right-column">
          <div className="contact-infos">
            <h4>Firstname</h4>
            <input
              type="text"
              name="firstname"
              value={profileData.firstname}
              onChange={handleInputChange}
              readOnly={!isEditable} // Toggle read-only based on edit mode
            />

            <h4>Lastname</h4>
            <input
              type="text"
              name="lastname"
              value={profileData.lastname}
              onChange={handleInputChange}
              readOnly={!isEditable} // Toggle read-only based on edit mode
            />

            <h4>Email</h4>
            <input
              type="text"
              name="email"
              value={profileData.email}
              readOnly={true}
            />

            <h4>Phone Number</h4>
            <input
              type="text"
              name="phone"
              value={profileData.phone}
              onChange={handleInputChange}
              readOnly={!isEditable}
            />

            <h4>Gender</h4>
            <select
              name="gender"
              value={profileData.gender}
              onChange={handleInputChange}
              disabled={!isEditable} // Disable select input when not in edit mode
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>

            <h4>Next of Kin Name</h4>
            <input
              type="text"
              name="nextOfKinName"
              value={profileData.nextOfKinName}
              onChange={handleInputChange}
              readOnly={!isEditable}
            />

            <h4>Next of Kin's Phone Number</h4>
            <input
              type="text"
              name="nextOfKinPhone"
              value={profileData.nextOfKinPhone}
              onChange={handleInputChange}
              readOnly={!isEditable}
            />

            <h4>Address</h4>
            <input
              type="text"
              name="address"
              value={profileData.address}
              onChange={handleInputChange}
              readOnly={!isEditable}
            />

            {!isEditable ? (
              <button className="save-button" onClick={handleEditClick}>
                Update Profile
              </button>
            ) : (
              <button className="save-button" onClick={handleSaveClick}>
                Save Changes
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
