import React, { useState } from "react";
import "./header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
  faPinterestP,
} from "@fortawesome/free-brands-svg-icons";
import { faTimes, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [showServicesDropdown, setShowServicesDropdown] = useState(false);
  const moveToHome = () => {
    navigate("/");
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleServicesDropdown = () => {
    setShowServicesDropdown(!showServicesDropdown);
  };

  return (
    <>
      <header className="header">
        <div className="header-top">
          <div className="contact-info">
            <span>📞 +234 7053 880 045, +234 8036 221 969</span>
            <span>🕒 Monday to Sunday: 9:00am – 6:00pm</span>
          </div>
          <div className="additional-info">
            <div className="social-icons">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a
                href="https://www.pinterest.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faPinterestP} />
              </a>
            </div>
          </div>
        </div>
        <div className="header-bottom">
          <div className="logo-container" onClick={() => moveToHome()}>
            <img
              src={require("../../images/logo.png")}
              alt="Transpi"
              className="logo"
            />
            <span className="company-name">
              ANAMBRA MOVEMENT TRANSPORT COMPANY LTD
            </span>
          </div>
          <nav className={`nav ${isOpen ? "open" : ""}`}>
            <ul>
              <li>
                <a href="/">HOME</a>
              </li>
              <li>
                <a href="/About">ABOUT</a>
              </li>
              <li
                className="services-dropdown"
                onMouseEnter={toggleServicesDropdown}
                onMouseLeave={toggleServicesDropdown}
              >
                <a href="#service">
                  SERVICES <FontAwesomeIcon icon={faChevronDown} />
                </a>
                {showServicesDropdown && (
                  <ul className="dropdown">
                    <li>
                      <a href="/">TRANSPORT SERVICES</a>
                    </li>
                    <li>
                      <a href="/logistics">COURIER AND LOGISTICS</a>
                    </li>
                    <li>
                      <a href="/storage">MOVING AND STORAGE</a>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <a href="#contact">CONTACT</a>
              </li>
              <li className="header-btn"><Link to="/login">Login</Link></li>
              <li className="header-btn"><Link to="/signup">Sign Up</Link></li>
            </ul>
          </nav>

          <button className="hamburger" onClick={toggleMenu}>
            {isOpen ? <FontAwesomeIcon icon={faTimes} /> : "☰"}
          </button>
        </div>
      </header>
    </>
  );
};

export default Header;