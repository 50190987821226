import React, { useState } from "react";
import "./admin_new.css";
import { IoCloseCircle } from "react-icons/io5";
import { createstorage } from "../../service/logistics";

const NewStorage = ({ onClose }) => {
  const pickup = "--Pickup Location--";
  const [pickuplocation, pickuplocationChanged] = useState(
    "--Select Pickup Location--"
  );
  const [formData, formDataChanged] = useState({
    uid: String,
    fullname: String,
    email: String,
    phone: String,
    ownership: String,
    duration: Number,
    period:'-',
    itemDescription: String,
    itemWeightInKg: Number,
    createdDate: Date,
    dropOffDate: Date,
    RetrievalDate: Date,
    deliveryLocation: String,
    pickUpLocation: String,
    fare: Number,
    isOnlineBooking: Boolean,
    review: String,
    rating: String,
    attendant: String,
    status: String,
    bookingRef: String,
    imageLoc: String,
  });
  const bookLogistic = async () => {
    let result = await createstorage(formData);
    if (result.status === 200) {
      alert(result.data);
      formDataChanged({
        uid: String,
        fullname: String,
        email: String,
        phone: String,
        period: String,
        duration: Number,
        itemDescription: String,
        itemWeightInKg: Number,
        createdDate: Date,
        dropOffDate: Date,
        RetrievalDate: Date,
        deliveryLocation: String,
        pickUpLocation: String,
        fare: Number,
        isOnlineBooking: Boolean,
        review: String,
        rating: String,
        attendant: String,
        status: String,
        bookingRef: String,
        imageLoc: String,
      });
      onClose()
    } else {
      alert(result.data);
    }
  };
  
  return (
    <div className="admin-modal-overlay">
      <div className="request-form admin-dialog-container">
         <div className="modal-header">
           <span className="close-button" onClick={onClose}>
             <IoCloseCircle style={{margin:'10px 5px 5px 5px'}}/>
           </span>{" "}
            <div className="logistic-header">
                 <h5>Item Description</h5>
             </div>
         </div>
        <div className="logistics-flex">
          <div className="form-group">
            <input
              type="text"
              value={formData.itemDescription}
              placeholder="Tell us about your item"
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  itemDescription: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <input
              type="number"
              placeholder="Item Weight (Kg)"
              value={formData.itemWeightInKg}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  itemWeightInKg: e.target.value,
                })
              }
            />
          </div>
          <div
            className="form-group"
            style={{ gridColumnStart: 1, gridColumnEnd: 3 }}
          >
            <p
              style={{ color: "black", fontSize: "13px", marginBottom: "10px" }}
            >
              Upload item image
            </p>
            <input
              type="file"
              placeholder=""
              value={formData.imageLoc}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  imageLoc: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="logistic-header">
          <h4>Storage Details</h4>
        </div>
        <div className="logistics-flex">
          <div className="form-group">
            <select
              value={formData.period}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  period: e.target.value,
                })
              }
            >
              <option>Estimated Storage Period</option>
              <option>Days</option>
              <option>Weeks</option>
              <option>Months</option>
              <option>Years</option>
            </select>
          </div>
          <div className="form-group">
            <select
              value={pickuplocation}
              onChange={(chg) => pickuplocationChanged(chg.target.value)}
            >
              <option>{pickup}</option>
              <option>Pick-Up from My Home</option>
              <option>Drop-Off at Company Location</option>
            </select>
          </div>

          <div className="form-group">
            <input
              type="number"
              value={formData.duration}
              placeholder={`Duration (${formData.period})`}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  duration: e.target.value,
                })
              }
            />
          </div>

          {pickuplocation === "Pick-Up from My Home" ? (
            <div className="form-group">
              <input
                type="text"
                placeholder="Pick up Address"
                onChange={(e) =>
                  formDataChanged({
                    ...formData,
                    pickUpLocation: e.target.value,
                  })
                }
              />
            </div>
          ) : (
            <div className="form-group">
              <select
                value={formData.pickUpLocation}
                onChange={(e) =>
                  formDataChanged({
                    ...formData,
                    pickUpLocation: e.target.value,
                  })
                }
              >
                <option>--Select Location--</option>
                <option>Lagos</option>
                <option>Benue</option>
              </select>
            </div>
          )}
        </div>
        <div className="logistic-header">
          <h4>Customer Details</h4>
        </div>
        <div className="logistics-flex">
          <div className="form-group">
            <input
              type="text"
              placeholder="Fullname"
              value={formData.fullname}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  fullname: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              value={formData.phone}
              placeholder="Phone Number"
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  phone: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <input
              type="txt"
              placeholder="Email Address"
              value={formData.email}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  email: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <select
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  ownership: e.target.value,
                })
              }
            >
              <option>Do you own the Item?</option>
              <option>Yes</option>
              <option>No (by-proxy)</option>
            </select>
          </div>
        </div>
        <button className="submit-button" onClick={() => bookLogistic()}>
          Submit request
        </button>
      </div>
    </div>
  );
};

export default NewStorage;

// function NewStorage({ onSubmit, onClose }) {
//   const [bookingData, setBookingData] = useState({
//     name: "",
//     vehicleType: "",
//     seats: "",
//     paymentMethod: "",
//     tripDestination: "",
//     tripPickup: "",
//     phone: "",
//     nextOfKinName: "",
//     nextOfKinPhone: "",
//     pickupOption: "home",
//     address: {
//       currentAddress: "",
//       residentialAddress: "",
//       state: "",
//       city: "",
//     },
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setBookingData({
//       ...bookingData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     onSubmit({ ...bookingData, id: Date.now() });
//     setBookingData({
//       name: "",
//       vehicleType: "",
//       seats: "",
//       paymentMethod: "",
//       tripDestination: "",
//       tripPickupTerminal: "",
//       phoneNumber: "",
//       nextOfKinName: "",
//       nextOfKinPhone: "",
//       pickupOption: "",
//       address: {
//         currentAddress: "",
//         residentialAddress: "",
//         state: "",
//         city: "",
//       },
//     });
//   };

//   return (
//     <div className="admin-modal-overlay">
//       <div className="admin-dialog-container">
//         <div className="modal-header">
//           <span className="close-button" onClick={onClose}>
//             <IoCloseCircle style={{margin:'10px 5px 5px 5px'}}/>
//           </span>{" "}
//             <div className="logistic-header">
//                 <h5>New Trip</h5>
//             </div>
//         </div>
//         <form onSubmit={handleSubmit}>
//           <div className="modal-row">
//             <div className="modal-field">
//               <select
//                 className="modal-input"
//                 onChange={(e) =>
//                   setBookingData({ ...bookingData, to: e.target.value })
//                 }
//               >
//                 <option value="">--Select Station--</option>
//                 <option value="Aba">Aba</option>
//                 <option value="Abakaliki">Abakaliki</option>
//                 <option value="Abuja">Abuja</option>
//                 <option value="Awka">Awka</option>
//                 <option value="Ekwulobia">Ekwulobia</option>
//                 <option value="Lagos">Lagos</option>
//                 <option value="Onitsha">Onitsha</option>
//                 <option value="Owerri">Owerri</option>
//                 <option value="PH">PH</option>
//               </select>
//             </div>

//             <div className="modal-field">
//               <select
//                 className="modal-input"
//                 onChange={(e) =>
//                   setBookingData({ ...bookingData, to: e.target.value })
//                 }
//               >
//                 <option value="">--Select Destination--</option>
//                 <option value="Aba">Aba</option>
//                 <option value="Abakaliki">Abakaliki</option>
//                 <option value="Abuja">Abuja</option>
//                 <option value="Awka">Awka</option>
//                 <option value="Ekwulobia">Ekwulobia</option>
//                 <option value="Lagos">Lagos</option>
//                 <option value="Onitsha">Onitsha</option>
//                 <option value="Owerri">Owerri</option>
//                 <option value="PH">PH</option>
//               </select>
//             </div>
//           </div>
//           <div className="modal-row">
//             <div className="modal-field">
//               <input
//                 type="text"
//                 placeholder="Trip Date"
//                 name="name"
//                 value={bookingData.name}
//                 onChange={handleChange}
//                 className="modal-input"
//                 required
//               />
//             </div>
//             <div className="modal-field">
//               <select
//                 className="modal-input"
//                 onChange={(e) =>
//                   setBookingData({ ...bookingData, to: e.target.value })
//                 }
//               >
//                 <option value="">--Select Time--</option>
//                 <option value="Aba">7:00 AM</option>
//                 <option value="Abakaliki">9:00 AM</option>
//                 <option value="Abuja">Night Bus (6:00PM)</option>
//               </select>
//             </div>
//           </div>
//           <div className="logistic-header">
//                 <h5>Passenger Details</h5>
//             </div>
//             <br></br>
//           <div className="modal-row">
//             <div className="modal-field">
//               <input
//                 type="text"
//                 placeholder="Fullname"
//                 name="name"
//                 value={bookingData.name}
//                 onChange={handleChange}
//                 className="modal-input"
//                 required
//               />
//             </div>
//             <div className="modal-field">
//               <input
//                 type="text"
//                 placeholder="Phone Number"
//                 name="name"
//                 value={bookingData.name}
//                 onChange={handleChange}
//                 className="modal-input"
//                 required
//               />
//             </div>
//             <div className="modal-field">
//               <select
//                 type="text"
//                 name="vehicleType"
//                 value={bookingData.vehicleType}
//                 onChange={handleChange}
//                 className="modal-input"
//                 required
//               >
//                 <option>--Choose Gender--</option>
//                 <option>Male</option>
//                 <option>Female</option>
//               </select>
//             </div>
//           </div>
//           <div className="logistic-header">
//                 <h5>Payment/Seat Info</h5>
//             </div>
//             <br></br>
//           <div className="modal-row">
//             <div className="modal-field">
//               <input
//                 type="number"
//                 placeholder="Amount"
//                 name="name"
//                 value={bookingData.name}
//                 onChange={handleChange}
//                 className="modal-input"
//                 required
//               />
//             </div>
//             <div className="modal-field">
//               <select
//                 type="text"
//                 name="vehicleType"
//                 value={bookingData.vehicleType}
//                 onChange={handleChange}
//                 className="modal-input"
//                 required
//               >
//                 <option>--Payment Mode--</option>
//                 <option>POS</option>
//                 <option>Bank Transfer</option>
//                 <option>Cash</option>
//                 <option>Others</option>
//               </select>
//             </div>
//             <div className="modal-field">
//               <input
//                 type="text"
//                 placeholder="Seat Number"
//                 name="name"
//                 value={bookingData.name}
//                 onChange={handleChange}
//                 className="modal-input"
//                 required
//               />
//             </div>
//           </div>
//           <div className="logistic-header">
//                 <h5>Next of Kin Details</h5>
//             </div>
//             <br></br>
//           <div className="modal-row">
//             <div className="modal-field">
//               <input
//                 type="text"
//                 placeholder="Fullname"
//                 name="name"
//                 value={bookingData.name}
//                 onChange={handleChange}
//                 className="modal-input"
//                 required
//               />
//             </div>
//             <div className="modal-field">
//               <input
//                 type="text"
//                 placeholder="Phone Number"
//                 name="name"
//                 value={bookingData.name}
//                 onChange={handleChange}
//                 className="modal-input"
//                 required
//               />
//             </div>
//           </div>
//           <br></br>
//           {/* Add additional rows for other fields */}
//           <button type="submit" className="admin-modal-button">
//             Book
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default NewStorage;
