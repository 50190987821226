// apiService.js

import axios from "axios";
import { ROOT_URL } from "./base";
import { checkUnauthorisedError, getPassenger } from "./general";
import { getAdmin, getAuthHeaders } from "./users";
const BASE_URL = `${ROOT_URL}/trips`;


export const fetchAdminTrips = async () => {
  try {
    var journeyUid=getPassenger()
    if(journeyUid===undefined){
      let response = await axios.post(`${BASE_URL}/fetch-all-trips`,getAdmin(), {
        headers: getAuthHeaders(),
      });
      return response.data;
    }
    else{
      let admin=getAdmin();
      admin.journeyUid=journeyUid;
      let response = await axios.post(`${BASE_URL}/fetch-all-trips-by-uid`,admin, {
        headers: getAuthHeaders(),
      });
      return response.data;
    }
  } catch (error){
    return checkUnauthorisedError(error, []);
  }
};

export const fetchFareCost = async (reqData) => {
  try {
    var response = await axios.get(`${BASE_URL}/fetch-fare-cost`,reqData);
    return response.data;
  } catch(error) {
    return checkUnauthorisedError(error, []);
  }
};


export const fetchFareTrips = async (email) => {
  try {
    var response = await axios.get(`${BASE_URL}/fetch-trips?email=${email}`, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch(error) {
    return checkUnauthorisedError(error, []);
  }
};

export const createTrip = async (reqData) => {
  try {
    if(!reqData.destination){
      reqData.destination=reqData.to;
    }
    
    const response = await axios.post(`${BASE_URL}/create-trip`, reqData);
    return response;
  } catch (error) {
    return checkUnauthorisedError(error, {
      status:400,
      data:'Error Occurred while booking record'
    });
  }
  
};
export const fetchTripStatus = async (reqData) => {
  try {
    const response = await axios.post(`${BASE_URL}/fetch-status`,reqData);
    return response;
  } catch (error) {
    return checkUnauthorisedError(error, {
      status:400,
      data:'Error Occurred while booking record'
    });
  }
  
};

export const checkIn = async (reqData) => {
  try {
    const response = await axios.post(`${BASE_URL}/check-in`,reqData, {
      headers: getAuthHeaders(),
    });
    return response;
  } catch (error) {
    return checkUnauthorisedError(error, {
      status:400,
      data:'Error Occurred while booking record'
    });
  }
};

export const userReview = async (reqData) => {

  try {
    const response = await axios.post(`${BASE_URL}/user-review`,reqData);
    return response;
  } catch (error) {
    return checkUnauthorisedError(error, {
      status:400,
      data:'Error Occurred while booking record'
    });
  }
};
export const arrivedDestination = async (reqData) => {
  try {
    const response = await axios.post(`${BASE_URL}/arrived-destination`,reqData,{
      headers: getAuthHeaders(),
    });
    return response;
  } catch (error) {
    return checkUnauthorisedError(error, {
      status:400,
      data:'Error Occurred while booking record'
    });
  }
};
