import React from "react";
import { useNavigate } from "react-router-dom";
import "./services.css";

const Services = () => {
  const navigate = useNavigate();

  const handleReadMore = (path) => {
    navigate(path);
  };

  return (
    <section className="services-section" id="service">
      <div className="services-header">
        <h3>SERVICES</h3>
        <p className="description">
          Tramas Transport Company specializes in providing reliable and
          efficient road transportation and logistics services. Our
          comprehensive solutions ensure that our passengers and their goods are
          delivered safely and on time. Trust us for your transportation needs.
        </p>
      </div>
      <div className="courier-services-container">
        <div className="service-card">
          <img
            src={require("../../images/sienna.jpeg")}
            alt="Transport Services"
            className="service-image"
          />
          <img
            src={require("../../images/toyota.jpeg")}
            alt="Transport Services"
            className="service-image"
          />
          <h3 className="service-title">Transport Services</h3>
          <p className="service-description">
            Reliable road transportation services for passengers and goods.
          </p>
          <button onClick={() => handleReadMore("/")} className="read-more-btn">
            READ MORE
          </button>
        </div>

        <div className="service-card">
          <img
           src={require('../../images/tramascourier.jpeg')}
            alt="Courier and Logistics"
            className="service-image"
          />
          <h3 className="service-title">Courier and Logistics</h3>
          <p className="service-description">
            Efficient solutions for courier services and logistics management.
          </p>
          <button
            onClick={() => handleReadMore("/logistics")}
            className="read-more-btn"
          >
            READ MORE
          </button>
        </div>

        <div className="service-card">
        <img 
            src={require('../../images/movingtramas.jpeg')}
            alt="Moving and Storage"
            className="service-image"
          />
          <h3 className="service-title">Moving and Storage</h3>
          <p className="service-description">
            Safe and secure moving and storage solutions for your needs.
          </p>
          <button
            onClick={() => handleReadMore("/storage")}
            className="read-more-btn"
          >
            READ MORE
          </button>
        </div>
      </div>
    </section>
  );
};

export default Services;
