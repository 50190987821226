import React, { useState } from "react";
import "./admin_new.css";
import { IoCloseCircle } from "react-icons/io5";
import { createVehicle } from "../../service/journey";

const NewVehicleInventory = ({ onClose }) => {
  const [formData, formDataChanged] = useState({
    uid: String,
    vehicleNumber: String,
    licenceNumber: String,
    createdDate: Date,
    vehicleType: String,
    vehicleImage: String,
    status: "Active",
    driversDetails: {
        fullname:"",
        phone: "",
        nin: "",
        displaypicture:""
    },
    maintenanceDetails: [],
  });
  const createInventory = async () => {
    let result = await createVehicle(formData);
    if (result.status === 200) {
      alert(result.data);
      formDataChanged({
        uid: String,
        vehicleNumber: String,
        licenceNumber: String,
        createdDate: Date,
        vehicleType: String,
        vehicleImage: String,
        driversDetails: Object,
        status: "Active",
        maintenanceDetails: Object,
      });
      onClose()
    } else {
      alert(result.data);
    }
  };
  
  return (
    <div className="admin-modal-overlay">
      <div className="request-form admin-dialog-container">
         <div className="modal-header">
           <span className="close-button" onClick={onClose}>
             <IoCloseCircle style={{margin:'10px 5px 5px 5px'}}/>
           </span>{" "}
            <div className="logistic-header">
                 <h5>Vehicle Details</h5>
             </div>
         </div>
        <div className="logistics-flex">
          <div className="form-group">
            <input
              type="text"
              value={formData.licenceNumber}
              placeholder="Licence Number"
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  licenceNumber: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <input
              type="number"
              placeholder="Vehicle Number"
              value={formData.vehicleNumber}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  vehicleNumber: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <select
              value={formData.vehicleType}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  vehicleType:e.target.value
                })
              }>
                <option>--Select Vehicle Type--</option>
                <option>Bus</option>
                <option>Sienna</option>
            </select>
          </div>
          <div
            className="form-group"
          >
            <input
              type="file"
              placeholder=""
              value={formData.imageLoc}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  vehicleImage: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="logistic-header">
          <h4>Driver Details</h4>
        </div>
        <div className="logistics-flex">
          <div className="form-group">
            <input
              type="text"
              placeholder="Fullname"
              value={formData.driversDetails.fullname}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  driversDetails:{
                    fullname:e.target.value,
                    phone:formData.driversDetails.phone,
                    email:formData.driversDetails.email,
                    nin:formData.driversDetails.nin
                  },
                })
              }
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              value={formData.driversDetails.phone}
              placeholder="Phone Number"
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  driversDetails:{
                    fullname:formData.driversDetails.fullname,
                    phone:e.target.value,
                    email:formData.driversDetails.email,
                    nin:formData.driversDetails.nin
                  },
                })
              }
            />
          </div>
          <div className="form-group">
            <input
              type="txt"
              placeholder="Email Address"
              value={formData.driversDetails.email}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  driversDetails:{
                    fullname:formData.driversDetails.fullname,
                    phone:formData.driversDetails.phone,
                    email:e.target.value,
                    nin:formData.driversDetails.nin
                  },
                })
              }
            />
          </div>
          <div className="form-group">
            <input
              type="txt"
              placeholder="NIN"
              value={formData.driversDetails.nin}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  driversDetails:{
                    fullname:formData.driversDetails.fullname,
                    phone:formData.driversDetails.phone,
                    email:formData.driversDetails.email,
                    nin:e.target.value
                  },
                })
              }
            />
          </div>
        </div>
        <button className="submit-button" onClick={() => createInventory()}>
          Submit request
        </button>
      </div>
    </div>
  );
};

export default NewVehicleInventory;