import React, { useState } from "react";
import { gotoAdminPage } from "../../../service/general";
import { updateRoute } from "../../../service/routes";

function TableRowDetails({ booking }) {
  const [routeData, setRouteData] = useState(booking);

  const updateRouteData = async (event) => {
    event.preventDefault();
    try {
      const result = await updateRoute(routeData);
      if (result.status === 200) {
        alert("Route updated successfully");
        gotoAdminPage();
      } else {
        alert("Failed to update the route");
      }
    } catch (error) {
      console.error("Error updating route:", error);
      alert("An error occurred while updating the route");
    }
  };

  return (
    <form className="admin-datatable-main" onSubmit={updateRouteData}>
      <div className="admin-datatable-header">
        <h3>Route Details</h3>
        <button type="submit">Save Changes</button>
      </div>
      <div className="admin-datatable-details">
        <div className="admin-dt-container">
          <label className="booking-label" htmlFor="vehicleType">
            Vehicle Type
          </label>
          <select
            id="vehicleType"
            className="modal-input"
            value={routeData.vehicleType}
            onChange={(e) =>
              setRouteData({ ...routeData, vehicleType: e.target.value })
            }
          >
            <option value="">--Select Vehicle Type--</option>
            <option value="Bus">Bus</option>
            <option value="Sienna">Sienna</option>
          </select>
        </div>
        <div className="admin-dt-container">
          <label className="booking-label" htmlFor="travellingFrom">
            Travelling From
          </label>
          <input
            id="travellingFrom"
            type="text"
            className="modal-input"
            value={routeData.travellingFrom}
            onChange={(e) =>
              setRouteData({ ...routeData, travellingFrom: e.target.value })
            }
          />
        </div>
        <div className="admin-dt-container">
          <label className="booking-label" htmlFor="travellingTo">
            Travelling To
          </label>
          <input
            id="travellingTo"
            type="text"
            className="modal-input"
            value={routeData.travellingTo}
            onChange={(e) =>
              setRouteData({ ...routeData, travellingTo: e.target.value })
            }
          />
        </div>
        <div className="admin-dt-container">
          <label className="booking-label" htmlFor="departureTime">
            Departure Time
          </label>
          <select
            id="departureTime"
            className="modal-input"
            value={routeData.departureTime}
            onChange={(e) =>
              setRouteData({ ...routeData, departureTime: e.target.value })
            }
          >
            <option value="">--Select Departure Time--</option>
            <option value="09:00 AM">09:00 AM</option>
            <option value="03:00 PM">03:00 PM</option>
            <option value="Night">Night</option>
          </select>
        </div>
        <div className="admin-dt-container">
          <label className="booking-label" htmlFor="price">
            Price
          </label>
          <input
            id="price"
            type="number"
            className="modal-input"
            value={routeData.price}
            onChange={(e) =>
              setRouteData({ ...routeData, price: e.target.value })
            }
          />
        </div>
      </div>
    </form>
  );
}

export default TableRowDetails;