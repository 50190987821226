import React, { useEffect, useState } from "react";
import TableRow from "./tablerow";
import "../datatable.css";
import "../adminlogistics.css";
import "../vehicle.css";
import SearchFilter from "./searchfilter";
import TableRowDetails from "./tablerowdetails";
import NewVehicleInventory from "../newvehicle";
import { fetchRoutes } from "../../../service/routes";
import NewRouteInventory from "./newrouteinventory";

function AdminRouteInventory() {
  const [routes, routesChanged] = useState([
    {
      uid: "",
      travellingFrom: "",
      travellingTo: "",
      travellingInfo: "",
    },
  ]);
  const [updateMode, setUpdateMode] = useState(false);
  const [filterStatus, setFilterStatus] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [isNewRoute, setIsNewRoute] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchRouteDetails = async () => {
      const data = await fetchRoutes();
      routesChanged(data);
    };
    fetchRouteDetails();
  }, []);

  const filteredData = routes.filter((route) => {
    try {
      const matchesSearchTerm =
        route.travellingFrom
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        route.vehicleType.toLowerCase().includes(searchTerm.toLowerCase())

      return matchesSearchTerm;
    } catch {
      return true;
    }
  });

  const handleNewRoute=(route)=>{
    if(route){
      setSelectedRoute(route);
    }
    setIsNewRoute(true)
  }
  // Handle viewing booking details
  const handleView = (booking) => {
    var model =
      selectedRoute === null || selectedRoute.uid !== booking.uid
        ? booking
        : null;
    setSelectedRoute(model);
    setUpdateMode(false);
  };
  const handleUpdate = (item) => {
    handleNewRoute(item);
    // handleView(booking);
    // setUpdateMode(true);
  };

  // Handle deleting a booking
  const handleDelete = (id) => {
    const updatedData = routes.filter((booking) => booking.id !== id);
    routesChanged(updatedData);
    alert("Booking deleted successfully!");
  };

  // Handle new booking form toggle
  const handleNewBooking = () => {
    setIsNewRoute(true);
    setSelectedRoute(null);
  };

  // Handle booking submission
  const handleBookingSubmit = (newBooking) => {
    routesChanged([...routes, newBooking]);
    setIsNewRoute(false);
    alert("New booking added successfully!");
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const handleDateSubmit = () => {
    console.log("Date filter applied:", startDate, endDate);
  };

  const handleTodayClick = () => {
    const today = new Date().toISOString().split("T")[0];
    setStartDate(today);
    setEndDate(today);
  };
  const handleDateClear = () => {
    setStartDate("");
    setEndDate("");
  };
  useEffect(() => {
    const fetchRouteDetails = async () => {
      var data = await fetchRoutes();
      routesChanged(data);
    };
    fetchRouteDetails();
  }, []);

  return (
    <div className="invoice-table">
      <SearchFilter
        setSearchTerm={setSearchTerm}
        onNewBooking={()=>handleNewRoute(null)}
        setFilterStatus={setFilterStatus}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        handleDateSubmit={handleDateSubmit}
        handleTodayClick={handleTodayClick}
        handleDateClear={handleDateClear}
        startDate={startDate}
        endDate={endDate}
      />
      <table>
        <thead>
          <tr>
            <th>Travelling from</th>
            <th>Travelling To</th>
            <th>Travelling Info</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((route, index) => (
            <React.Fragment key={index}>
              <TableRow
                route={route}
                onView={handleView}
                onDelete={handleDelete}
                onUpdate={handleUpdate}
                isSelected={
                  selectedRoute !== null &&
                  selectedRoute.uid === route.uid
                }
              />
              {/* {selectedRoute !== null &&
                selectedRoute.uid === route.uid && (
                  <tr>
                    <td colSpan="10">
                      <TableRowDetails booking={route} />
                    </td>
                  </tr>
                )} */}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {isNewRoute && (
        <NewRouteInventory
          show={isNewRoute}
          routeDetails={selectedRoute}
          onClose={() => setIsNewRoute(false)}
        />
      )}
    </div>
  );
}

export default AdminRouteInventory;