import React from 'react';
import './logistics.css';
import './storage.css';
import DashboardHeader from '../dashboard/dashboardheader';
import Footer from '../footer/footer';
import StorageSlide from './storageslide';
import Storagecomp from '../booking/storagecomp';

const Storage= () => {

  return (
    <div >
    <DashboardHeader></DashboardHeader>
    <div className='storage'>
        <div className="storage-container">
        <div className="storage-content">
          <h1>TRAMAS STORAGE AND MOVING</h1>
          <p>
          Your solution for first class warehousing services for the goods, food, grocery, pharmaceutical and general commodity industries.
          </p>
         
          
          <div className="storage-buttons">
            <button className="cal-btn">CALL FOR MORE INFO</button>
           
          </div>
        </div>
      </div>
      <div><StorageSlide></StorageSlide></div>
      <div className="storage-page">

      <div className="left-secxtion">
        <h2>In need of our Courier Services?</h2>
        <p>Place Your Request for our Storage Services</p>
        <p>It only takes 30 seconds and then we’ll call you back, Monday to Friday, 9am-5pm. Easy.</p>
        <p>Or Contact Us at 123-45-67</p>
      </div>
      <Storagecomp></Storagecomp>
    </div>
    </div>
    <Footer></Footer>
    </div>
  );
};

export default Storage;
