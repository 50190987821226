import React from "react";
import { MdClearAll } from "react-icons/md";
import "../datatable.css";
import "../adminlogistics.css";
import "../vehicle.css";

function SearchFilter({
  setSearchTerm,
  onNewBooking,
  setFilterStatus,
  handleStartDateChange,
  handleEndDateChange,
  handleDateSubmit,
  handleTodayClick,
  handleDateClear,
  startDate,
  endDate,
}) {
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="header">
      <h2>Route Inventory</h2>
      <div className="search-filter">
        <div className="search">
          <input
            type="text"
            placeholder="Search"
            className="search-input"
            onChange={handleSearchChange}
          />
          <button className="new-invoice-btn" onClick={onNewBooking}>
            New Route
          </button>
        </div>
      </div>
    </div>
  );
}

export default SearchFilter;