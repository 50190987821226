import axios from "axios";
import { ROOT_URL } from "./base";
import { checkUnauthorisedError } from "./general";
import { getAdmin, getAuthHeaders } from "./users";
const BASE_URL = `${ROOT_URL}/logistics`;

export const fetchLogistics= async (email) => {
  try {
    var response = await axios.get(`${BASE_URL}/fetch-logistics?email=${email}`, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch(error) {
    return checkUnauthorisedError(error, []);
  }
};
export const fetchAdminLogistics= async () => {
  try {
    var response = await axios.post(`${BASE_URL}/fetch-all-logistics`,getAdmin(), {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch(error) {
    return checkUnauthorisedError(error, []);
  }
};
export const fetchAdminStorage= async () => {
  try {
    var response = await axios.post(`${BASE_URL}/fetch-all-storage`,getAdmin(), {
      headers: getAuthHeaders(),
    });
    console.log(response.data)
    return response.data;
  } catch(error) {
    return checkUnauthorisedError(error, []);
  }
};
export const fetchStorage= async (email) => {
  try {
    var response = await axios.get(`${BASE_URL}/fetch-storage?email=${email}`, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch(error) {
    return checkUnauthorisedError(error, []);
  }
};

export const createlogistic = async (reqData) => {
  try {
    const response = await axios.post(`${BASE_URL}/create-logistic`, reqData, {
      headers: getAuthHeaders(),
    });
    return response;
  } catch (error) {
    return checkUnauthorisedError(error, {
      status:400,
      data:'Error Occurred while booking record'
    });
  }
};
export const createstorage = async (reqData) => {
  try {
    const response = await axios.post(`${BASE_URL}/create-storage`, reqData, {
      headers: getAuthHeaders(),
    });
    return response;
  } catch (error) {
    return checkUnauthorisedError(error, {
      status:400,
      data:'Error Occurred while booking record'
    });
  }
};
export const updatestorage = async (reqData) => {
  try {
    console.log(reqData);
    const response = await axios.post(`${BASE_URL}/update-storage`, reqData, {
      headers: getAuthHeaders(),
    });
    return response;
  } catch (error) {
    return checkUnauthorisedError(error, {
      status:400,
      data:'Error Occurred while booking record'
    });
  }
};

export const updatelogistic = async (reqData) => {
  try {
    const response = await axios.post(`${BASE_URL}/update-logistic`, reqData, {
      headers: getAuthHeaders(),
    });
    return response;
  } catch (error) {
    return checkUnauthorisedError(error, {
      status:400,
      data:'Error Occurred while booking record'
    });
  }
};

export const userReview = async (reqData) => {
  try {
    const response = await axios.post(`${BASE_URL}/user-review`, reqData);
    return response;
  } catch (error) {
    return checkUnauthorisedError(error, {
      status:400,
      data:'Error Occurred while booking record'
    });
  }
};

export const arrivedDestination = async (reqData) => {
  try {
    const response = await axios.post(`${BASE_URL}/arrived-destination`, reqData,{
      headers: getAuthHeaders(),
    });
    return response;
  } catch (error) {
    return checkUnauthorisedError(error, {
      status:400,
      data:'Error Occurred while booking record'
    });
  }
};

export const updateDelivery = async (reqData) => {
  try {
    const response = await axios.post(`${BASE_URL}/update-delivery`, reqData,{
      headers: getAuthHeaders(),
    });
    return response;
  } catch (error) {
    return checkUnauthorisedError(error, {
      status:400,
      data:'Error Occurred while booking record'
    });
  }
};
export const findPackageAsync = async (trackingNumber) => {
  try {
    const response = await axios.get(`${BASE_URL}/findPackage?uid=${trackingNumber}`);
    return response;
  } catch (error) {
    return checkUnauthorisedError(error, {
      status:400,
      data:'Error Occurred while booking record'
    });
  }
};



