import React, { useEffect, useState } from "react";
import "./datatable.css";
import "./adminlogistics.css";
import NewTrip from "./newtrip.js";
import { checkIn, fetchAdminTrips } from "../../service/trips";
import {
  BsArrowRightCircleFill,
  BsCheckSquareFill,
  BsCalendar2Check,
  BsEyeSlash,
  BsEye,
  BsPrinterFill,
} from "react-icons/bs";
import {
  formatDate,
  formatDateTime,
  formatTime,
  gotoAdminPage,
} from "../../service/general";
import { MdCancel, MdClearAll, MdContentCopy } from "react-icons/md";

function TableRow({ booking, onView, onUpdate, isSelected }) {
  const checkUserIn = (booking) => {
    checkIn(booking);
    gotoAdminPage();
  };
  return (
    <tr className={isSelected ? "selected-row" : ""}>
      <td>
        <div className="person-profile">
          <h3>{booking.fullname}</h3>
          <p>
            {booking.phone}{" "}
            <MdContentCopy
              className="phoneCopy"
              onClick={() => navigator.clipboard.writeText(booking.phone)}
            ></MdContentCopy>
          </p>
          <p className="email">{booking.email}</p>
        </div>
      </td>
      <td>
        <div>
          <p
            style={{
              fontSize: "15px",
              marginBottom: "10px",
              display: "flex",
              columnGap: "5px",
            }}
          >
            <b>{booking.from}</b>
            <BsArrowRightCircleFill></BsArrowRightCircleFill>
            <b>{booking.destination}</b>
          </p>
          <p style={{ display: "flex", columnGap: "5px", fontSize: "12px" }}>
            <BsCalendar2Check></BsCalendar2Check>
            <b>
              {formatDate(booking.departureDate)} {booking.departureTime}
            </b>
          </p>
          {booking.pickUpLocation === "other" ? (
            ""
          ) : (
            <p
              style={{
                fontSize: "11px",
                fontWeight: "bold",
                marginTop: "10px",
              }}
            >
              Pickup Location:{" "}
              <span style={{ color: "green" }}>{booking.pickUpLocation}</span>
            </p>
          )}
        </div>
      </td>
      <td>
        <div>
          <p style={{ fontSize: "25px" }}>
            <b>{new Intl.NumberFormat().format(booking.fare)} NGN</b>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "5px",
              fontSize: "13px",
              marginTop: "5px",
            }}
          >
            <b>{booking.paymentType}</b>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "5px",
              fontSize: "13px",
              marginTop: "5px",
            }}
          >
            {booking.paymentStatus === "Completed" && (
              <BsCheckSquareFill style={{ color: "green" }}></BsCheckSquareFill>
            )}
            {booking.paymentStatus === "Pending" && (
              <BsCheckSquareFill
                style={{ color: "goldenrod" }}
              ></BsCheckSquareFill>
            )}
            {booking.paymentStatus === "Cancelled" && (
              <MdCancel style={{ color: "red" }}></MdCancel>
            )}
            <span style={{ fontSize: "11px" }}>{booking.paymentStatus}</span>
          </p>
        </div>
      </td>
      <td>
        <div>
          {booking.hasArrived ? "Arrived Destination" : "On Transit"}
          <br></br>
          <br></br>
          <p style={{ fontSize: "11px" }}>
            Checked-In Time: <b>{formatDateTime(booking.checkInTime)}</b>
          </p>
        </div>
      </td>
      <td className="admin-action">
        <div className="admin-action-main">
          {isSelected ? (
            <BsEyeSlash
              onClick={() => onView(booking)}
              style={{ cursor: "pointer" }}
            ></BsEyeSlash>
          ) : (
            <BsEye
              onClick={() => onView(booking)}
              style={{ cursor: "pointer" }}
            ></BsEye>
          )}
          <BsCheckSquareFill
            onClick={() => checkUserIn(booking)}
            style={{ cursor: "pointer", marginLeft: "5px" }}
          ></BsCheckSquareFill>
          <BsPrinterFill
            style={{ cursor: "pointer", marginLeft: "5px" }}
          ></BsPrinterFill>
        </div>
      </td>
    </tr>
  );
}

function AdminTrip() {
  const [bookings, bookingsChanged] = useState([
    {
      uid: "",
      email: "",
      fullname: "",
      nextOfKinName: "",
      nextOfKinPhone: "",
      phone: "",
      from: "",
      destination: "",
      createdDate: new Date(),
      departureDate: new Date(),
      departureTime: "",
      returnDate: new Date(),
      pickUpLocation: "",
      seatNumber: 0,
      vehicleType: "",
      fare: 0,
      discount: 0,
      isOnlineBooking: false,
      paymentType: "",
      paymentRef: "",
      paymentStatus: "Pending",
      checkedIn: false,
      checkInTime: Date,
      arrivalDate: Date,
      hasArrived: false,
      arrivalRemarks: "",
      review: "",
      rating: "",
      vehicleNumber: "",
      driverName: "",
      bookingRef: "",
    },
  ]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isNewBooking, setIsNewBooking] = useState(false);
  const [filterStatus, setFilterStatus] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const filteredData = bookings.filter((booking) => {
    try {
      const matchesSearchTerm =
        booking.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.driverName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.from.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.destination.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.driverName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.phone.includes(searchTerm);
      const matchesStatus =
        filterStatus === "all" || booking.status.toLowerCase() === filterStatus;

    //   const matchesStatus =
    // filterStatus === "all" ||
    // (filterStatus === "on transit" && !booking.hasArrived) ||
    // (filterStatus === "arrived" && booking.hasArrived);

      const matchesDate =
        (!startDate || new Date(booking.date) >= new Date(startDate)) &&
        (!endDate || new Date(booking.date) <= new Date(endDate));

      return matchesSearchTerm && matchesStatus && matchesDate;
    } catch {
      return true;
    }
  });

  // Handle viewing booking details
  const handleView = (booking) => {
    var model =
      selectedBooking === null || selectedBooking.uid !== booking.uid
        ? booking
        : null;
    setSelectedBooking(model);
  };
  const handleUpdate = (booking) => {
    handleView(booking);
  };

  // Handle deleting a booking
  const handleDelete = (id) => {
    const updatedData = bookings.filter((booking) => booking.id !== id);
    bookingsChanged(updatedData);
    alert("Booking deleted successfully!");
  };

  // Handle new booking form toggle
  const handleNewBooking = () => {
    setIsNewBooking(true);
    setSelectedBooking(null);
  };

  // Handle booking submission
  const handleBookingSubmit = (newBooking) => {
    bookingsChanged([...bookings, newBooking]);
    setIsNewBooking(false);
    alert("New booking added successfully!");
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const handleDateSubmit = () => {
    console.log("Date filter applied:", startDate, endDate);
  };

  const handleTodayClick = () => {
    const today = new Date().toISOString().split("T")[0];
    setStartDate(today);
    setEndDate(today);
  };
  const handleDateClear = () => {
    setStartDate("");
    setEndDate("");
  };
  useEffect(() => {
    const fetchTripDetails = async () => {
      var data = await fetchAdminTrips();
      bookingsChanged(data);
    };
    fetchTripDetails();
  }, []);

  return (
    <div className="invoice-table">
      <SearchFilter
        setSearchTerm={setSearchTerm}
        onNewBooking={handleNewBooking}
        setFilterStatus={setFilterStatus}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        handleDateSubmit={handleDateSubmit}
        handleTodayClick={handleTodayClick}
        handleDateClear={handleDateClear}
        startDate={startDate}
        endDate={endDate}
      />
      <table>
        <thead>
          <tr>
            <th>Customer Details</th>
            <th>Trip Details</th>
            <th>Payment Details</th>
            <th>Trip Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((booking, index) => (
            <React.Fragment key={index}>
              <TableRow
                booking={booking}
                onView={handleView}
                onDelete={handleDelete}
                onUpdate={handleUpdate}
                isSelected={
                  selectedBooking !== null &&
                  selectedBooking.uid === booking.uid
                }
              />
              {selectedBooking !== null &&
                selectedBooking.uid === booking.uid && (
                  <tr>
                    <td colSpan="10">
                      <TableRowDetails booking={booking} />
                    </td>
                  </tr>
                )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {isNewBooking && (
        <NewTrip show={isNewBooking} onClose={() => setIsNewBooking(false)} />
      )}
    </div>
  );
}

function SearchFilter({
  setSearchTerm,
  onNewBooking,
  setFilterStatus,
  handleStartDateChange,
  handleEndDateChange,
  handleDateSubmit,
  handleTodayClick,
  handleDateClear,
  startDate,
  endDate,
}) {
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="header">
      <h2>Passengers</h2>
      <div className="search-filter">
        <div className="search">
          <input
            type="text"
            placeholder="Search"
            className="search-input"
            onChange={handleSearchChange}
          />
          <input
            type="date"
            className="date-input"
            value={startDate}
            onChange={handleStartDateChange}
          />
          <input
            type="date"
            className="date-input"
            value={endDate}
            onChange={handleEndDateChange}
          />
          <button
            className="admin-clear-btn"
            onClick={handleDateClear}
            title="Clear filter"
          >
            <MdClearAll></MdClearAll>
          </button>
          <button className="submit-btn" onClick={handleDateSubmit}>
            Submit
          </button>

          <button className="new-invoice-btn" onClick={onNewBooking}>
            New Passesnger
          </button>
        </div>
        <div className="filters">
          <button className="active" onClick={() => setFilterStatus("all")}>
            All Order
          </button>
          <button onClick={() => setFilterStatus("on transit")}>
            On-Transit
          </button>
          <button onClick={() => setFilterStatus("arrived")}>Arrived</button>
        </div>
      </div>
    </div>
  );
}

function TableRowDetails({ booking, updateMode }) {
  return (
    <div className="admin-datatable-main">
      <div className="admin-datatable-header">
        <h3 h3>Trip Details ({booking.bookingRef})</h3>
        {updateMode ? <button>Save Changes</button> : ""}
      </div>
      <br></br>
      <div className="admin-datatable-details">
        <div className="admin-dt-container">
          <p className="booking-label">Next of Kin</p>
          <p className="booking-value">Fullname: {booking.nextOfKinName}</p>
          <p className="booking-value">
            Phone Number: {booking.nextOfKinPhone}
          </p>
        </div>
        <div className="admin-dt-container">
          <p className="booking-label">Payment Details</p>
          <p className="booking-value">Payment Ref: {booking.paymentRef}</p>
          <p className="booking-value">Payment Type: {booking.paymentType}</p>
          <p className="booking-value">
            Payment Status: {booking.paymentStatus}
          </p>
        </div>
        <div className="admin-dt-container">
          <p className="booking-label">Important Dates</p>
          <p className="booking-value">
            <BsCalendar2Check></BsCalendar2Check>
            &nbsp;&nbsp;Booking Date: {formatDate(booking.createdDate)}
          </p>
          <p className="booking-value">
            <BsCalendar2Check></BsCalendar2Check>
            &nbsp;&nbsp;Departure Date: {formatDateTime(booking.departureDate)}
          </p>
          <p className="booking-value">
            <BsCalendar2Check></BsCalendar2Check>
            &nbsp;&nbsp;Return Date:
            {new Date(booking.returnDate) >
            new Date(formatDateTime(booking.departureDate))
              ? booking.returnDate
              : ""}
          </p>
          <p className="booking-value">
            <BsCheckSquareFill></BsCheckSquareFill>
            &nbsp;&nbsp;Checked-In:{" "}
            {booking.checkedIn ? formatTime(booking.checkInTime) : "Nil"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default AdminTrip;
