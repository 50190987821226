import React, { useEffect, useState } from "react";
import "./trips.css";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { fetchFareTrips } from "../../service/trips";
import Bookingcomp from "../booking/bookingcomp";
import { fetchRoutes } from "../../service/routes";

const Trips = () => {
  const [bookings, bookingsChanged] = useState([
    {
      uid: "",
      email: "",
      fullname: "",
      nextOfKinName: "",
      nextOfKinPhone: "",
      phone: "",
      from: "",
      destination: "",
      createdDate: new Date(),
      departureDate: new Date(),
      returnDate: new Date(),
      pickUpLocation: "",
      seatNumber: 0,
      vehicleType: "",
      fare: 0,
      discount: 0,
      isOnlineBooking: false,
      paymentType: "",
      paymentRef: "",
      paymentStatus: "Pending",
      checkedIn: false,
      checkInTime: Date,
      arrivalDate: Date,
      hasArrived: false,
      arrivalRemarks: "",
      review: "",
      rating: "",
      vehicleNumber: "",
      driverName: "",
      bookingRef: "",
    },
  ]);
  const [selectedBooking, selectedBookingChanged] = useState({
    uid: "",
    email: "",
    fullname: "",
    nextOfKinName: "",
    nextOfKinPhone: "",
    phone: "",
    from: "",
    destination: "",
    createdDate: new Date(),
    departureDate: new Date(),
    returnDate: new Date(),
    pickUpLocation: "",
    seatNumber: 0,
    vehicleType: "",
    fare: 0,
    discount: 0,
    isOnlineBooking: false,
    passenger: "",
    paymentType: "",
    paymentRef: "",
    paymentStatus: "Pending",
    checkedIn: false,
    checkInTime: Date,
    arrivalDate: Date,
    hasArrived: false,
    arrivalRemarks: "",
    review: "",
    rating: "",
    vehicleNumber: "",
    driverName: "",
    bookingRef: "",
  });
  const [openBooking, openBookingChanged] = useState(false);
  
  useEffect(() => {
    const fetchTripDetails = async () => {
      var data = await fetchFareTrips(localStorage.email);
      bookingsChanged(data);
    };
    fetchTripDetails();
  }, []);
  const [showNewBooking, showNewBookingChanged] = useState(false);
  const showNewBookingAction = () => {
    showNewBookingChanged(true);
  };
  const closePopup = () => {
    showNewBookingChanged(false);
  };

  return (
    <div>
      {showNewBooking ? (
        <div className="new-booking">
          <div className="new-booking-backdrop" onClick={closePopup}></div>
          <Bookingcomp onClose={closePopup} />
        </div>
      ) : (
        <div className="booking-container">
          <div className="order-table">
            <div className="order-header">
              <h3>Booking History</h3>
              <button
                className="add-order"
                onClick={() => showNewBookingAction()}
              >
                Schedule New Trip
              </button>
            </div>
            <center>
              {openBooking ? (
                <div className="booked-items booked-items-grid">
                  <BsArrowLeftCircleFill
                    style={{ fontSize: "30px" }}
                    onClick={() => openBookingChanged(false)}
                  ></BsArrowLeftCircleFill>
                  <p></p>
                  <p>Routes</p>
                  <p style={{ fontWeight: "normal" }}>{selectedBooking.from}</p>
                  <p></p>
                  <p style={{ fontWeight: "normal" }}>
                    {selectedBooking.destination}
                  </p>
                  <p>Booking</p>
                  <p style={{ fontWeight: "normal" }}>
                    {selectedBooking.paymentStatus} (
                    {selectedBooking.isOnlineBooking ? "Online" : "Physical"})
                  </p>
                  <p>Contact Name</p>
                  <p style={{ fontWeight: "normal" }}>
                    {selectedBooking.fullname}
                  </p>
                  <p>Contact Info</p>
                  <p style={{ fontWeight: "normal" }}>
                    {selectedBooking.phone} ({selectedBooking.email})
                  </p>
                  <p>Total Amount</p>
                  <p style={{ fontWeight: "normal" }}>
                    {new Intl.NumberFormat().format(selectedBooking.fare)} NGN
                  </p>
                  <p>Booking Date</p>
                  <p style={{ fontWeight: "normal" }}>
                    {new Date(selectedBooking.createdDate).toDateString()}
                  </p>
                  <p>Departure Date</p>
                  <p style={{ fontWeight: "normal" }}>
                    {new Date(selectedBooking.departureDate).toDateString()}
                  </p>
                  {Date(selectedBooking.returnDate) >
                  Date(selectedBooking.departureDate) ? (
                    <div>
                      <p>Return Date</p>
                      <p style={{ fontWeight: "normal" }}>
                        {new Date(selectedBooking.returnDate).toDateString()}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  <p>Passenger</p>
                  <p style={{ fontWeight: "normal" }}>
                    {selectedBooking.passenger}
                  </p>
                  <p>Next of Kin Name</p>
                  <p style={{ fontWeight: "normal" }}>
                    {selectedBooking.nextOfKinName}
                  </p>
                  <p>Next of Kin Phone</p>
                  <p style={{ fontWeight: "normal" }}>
                    {selectedBooking.nextOfKinPhone}
                  </p>
                </div>
              ) : (
                <>
                  {bookings.map((item) => (
                    <div
                      className="booked-items"
                      onClick={() => {
                        selectedBookingChanged(item);
                        openBookingChanged(true);
                      }}
                    >
                      <div className="booked-items-spacebetween">
                        <p>{`${item.from} ==> ${item.destination}`}</p>
                        <p>
                          <b>
                            {item.paymentStatus} (
                            {item.isOnlineBooking ? "Online" : "Physical"})
                          </b>
                        </p>
                      </div>
                      <div className="booked-items-spacebetween">
                        <div style={{ fontSize: "12px",textAlign:'left' }}>
                          <p>
                            Departure Date:{" "}
                            {new Date(item.departureDate).toDateString()}
                          </p>
                          <br></br>
                          {new Date(item.returnDate)>new Date(item.departureDate) ? (
                            <p>
                              Return Date:{" "}
                              {new Date(item.returnDate).toDateString()}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <p>
                          <b>{new Intl.NumberFormat().format(item.fare)} NGN</b>
                        </p>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </center>
          </div>
        </div>
      )}
    </div>
  );
};

export default Trips;
