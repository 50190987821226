import React, { useEffect, useState } from "react";
import "../services/logistics.css";
import { createlogistic, findPackageAsync } from "../../service/logistics";
import { getCurrentUser } from "../../service/users";

const Logisticcomp = () => {
  const pickup = "--Pickup Location--";
  const dropOff = "--Delivery Location--";
  const [pickuplocation, pickuplocationChanged] = useState(
    "--Select Pickup Location--"
  );
  const [dropOfflocation, dropOfflocationChanged] = useState(
    "--Select Delivery Location--"
  );
  const [formData, formDataChanged] = useState({
    fullname: "",
    email: "",
    phone: "",
    receiverName: "",
    receiverEmail: "",
    receiverPhone: "",
    itemDescription: "",
    itemWeightInKg: Number,
    createdDate: new Date(),
    pickUpLocation: "",
    deliveryLocation: "",
    isOnlineBooking: true,
    hasArrived: false,
  });
  const [isTrackItem, setIsTrackItem] = useState("Create New Request");
  const [trackingNumber, setTrackNumber] = useState("");
  const [packageStatus, setPackageStatus] = useState(" ---Not Found---");
  const bookLogistic = async () => {
    let result = await createlogistic(formData);
    if (result.status === 200) {
      alert(result.data);
      formDataChanged({
        fullname: "",
        email: "",
        phone: "",
        receiverName: "",
        receiverEmail: "",
        receiverPhone: "",
        itemDescription: "",
        itemWeightInKg: Number,
        createdDate: new Date(),
        pickUpLocation: "",
        deliveryLocation: "",
        isOnlineBooking: true,
        hasArrived: false,
      });
    } else {
      alert(result.data);
    }
  };

  const findPackage = async () => {
    var packageRequest = await findPackageAsync(trackingNumber);
    if (packageRequest.status === 200) {
      console.log(packageRequest)
      setPackageStatus(packageRequest.data);
    }
  };

  useEffect(() => {
    const refreshUser = async () => {
      const data = await getCurrentUser(true);
      if (data) {
        formDataChanged({
          ...formData,
          email: data.email,
          phone: data.phone,
          fullname: `${data.firstname} ${data.lastname}`,
        });
      }
    };
    refreshUser();
  }, []);
  return (
    <div className="right-section">
      <div className="request-form">
        <div className="logistic-header">
          <h4>Send/Receive A Package</h4>
        </div>
        <br></br>
        <div className="form-group">
          <select
            onChange={(e) => setIsTrackItem(e.target.value)}
            value={isTrackItem}
          >
            <option>--Select Option--</option>
            <option>Create New Request</option>
            <option>Track Your Item</option>
          </select>
        </div>
      </div>
      {isTrackItem === "Track Your Item" ? (
        <div className="request-form">
          <div className="logistic-header">
            <h4>Track Your Item</h4>
          </div>
          <div className="logistics-flex">
            <div className="form-group">
              <input
                type="text"
                placeholder="Tracking Number"
                onChange={(e) => setTrackNumber(e.target.value)}
              />
            </div>
          </div>
          <button className="submit-button" onClick={() => findPackage()}>
            Submit request
          </button>

          <p style={{ color: "black", margin: "10px 10px 0px 0px" }}>
            Status:{" "}
            <b style={{ color: "#2c3e50", fontWeight: "bold" }}>
              {packageStatus}
            </b>{" "}
          </p>
        </div>
      ) : (
        <div className="request-form">
          <div className="logistic-header">
            <h4>Item Description</h4>
          </div>
          <div className="logistics-flex">
            <div className="form-group">
              <input
                type="text"
                placeholder="Tell us about your item"
                onChange={(e) =>
                  formDataChanged({
                    ...formData,
                    itemDescription: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <input
                type="number"
                placeholder="Item Weight (Kg)"
                onChange={(e) =>
                  formDataChanged({
                    ...formData,
                    itemWeightInKg: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="logistic-header">
            <h4>Delivery Details</h4>
          </div>
          <div className="logistics-flex">
            <div className="form-group">
              <select
                value={pickuplocation}
                onChange={(chg) => pickuplocationChanged(chg.target.value)}
              >
                <option>{pickup}</option>
                <option>Pick-Up from My Home</option>
                <option>Drop-Off at Company Location</option>
              </select>
            </div>
            <div className="form-group">
              <select
                value={dropOfflocation}
                onChange={(chg) => dropOfflocationChanged(chg.target.value)}
              >
                <option>{dropOff}</option>
                <option>Door step Delivery</option>
                <option>Pick-Up at Company Location</option>
              </select>
            </div>
            {pickuplocation === "Pick-Up from My Home" ? (
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Pick up Address"
                  onChange={(e) =>
                    formDataChanged({
                      ...formData,
                      pickUpLocation: e.target.value,
                    })
                  }
                />
              </div>
            ) : (
              <div className="form-group">
                <select
                  onChange={(e) =>
                    formDataChanged({
                      ...formData,
                      pickUpLocation: e.target.value,
                    })
                  }
                >
                  <option>--Select Location--</option>
                  <option>Lagos</option>
                  <option>Benue</option>
                </select>
              </div>
            )}
            {dropOfflocation === "Door step Delivery" ? (
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Delivery Address"
                  onChange={(e) =>
                    formDataChanged({
                      ...formData,
                      deliveryLocation: e.target.value,
                    })
                  }
                />
              </div>
            ) : (
              <div className="form-group">
                <select
                  onChange={(e) =>
                    formDataChanged({
                      ...formData,
                      deliveryLocation: e.target.value,
                    })
                  }
                >
                  <option>--Select Delivery Location--</option>
                  <option>Enugu</option>
                  <option>Abuja</option>
                </select>
              </div>
            )}
          </div>
          <div className="logistic-header">
            <h4>Sender & Receiver</h4>
          </div>
          <div className="logistics-flex">
            <div className="form-group">
              <input
                type="text"
                placeholder="Sender fullname"
                value={formData.fullname}
                onChange={(e) =>
                  formDataChanged({
                    ...formData,
                    fullname: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Receiver fullname"
                onChange={(e) =>
                  formDataChanged({
                    ...formData,
                    receiverName: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <input
                type="txt"
                placeholder="Sender phone"
                value={formData.phone}
                onChange={(e) =>
                  formDataChanged({
                    ...formData,
                    phone: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Receiver phone"
                onChange={(e) =>
                  formDataChanged({
                    ...formData,
                    receiverPhone: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                placeholder="Sender Email"
                value={formData.email}
                onChange={(e) =>
                  formDataChanged({
                    ...formData,
                    email: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                placeholder="Receiver Email"
                onChange={(e) =>
                  formDataChanged({
                    ...formData,
                    receiverEmail: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <button className="submit-button" onClick={() => bookLogistic()}>
            Submit request
          </button>
        </div>
      )}
    </div>
  );
};

export default Logisticcomp;
