import React, { useState } from 'react';
import './datatable.css';
import { createlogistic } from '../../service/logistics';
import { IoCloseCircle } from 'react-icons/io5';

function NewDeliveryForm({ onClose}) {
  const pickup = "--Pickup Location--";
  const dropOff = "--Delivery Location--";
  const [pickuplocation, pickuplocationChanged] = useState(
    "--Select Pickup Location--"
  );
  const [dropOfflocation, dropOfflocationChanged] = useState(
    "--Select Delivery Location--"
  );
  const [formData, formDataChanged] = useState({
    fullname: "",
    email: "",
    phone: "",
    receiverName: "",
    receiverEmail: "",
    receiverPhone: "",
    itemDescription: "",
    itemWeightInKg: Number,
    createdDate: new Date(),
    pickUpLocation: "",
    deliveryLocation: "",
    isOnlineBooking: true,
    hasArrived: false,
  });
  const bookLogistic = async () => {
    let result = await createlogistic(formData);
    if (result.status === 200) {
      alert(result.data);
      formDataChanged({
        fullname: "",
        email: "",
        phone: "",
        receiverName: "",
        receiverEmail: "",
        receiverPhone: "",
        itemDescription: "",
        itemWeightInKg: Number,
        createdDate: new Date(),
        pickUpLocation: "",
        deliveryLocation: "",
        isOnlineBooking: true,
        hasArrived: false,})
    } else {
      alert(result.data);
    }
  };
  return (
    <div className="admin-modal-overlay">
      <div className="request-form admin-dialog-container">
         <div className="modal-header">
           <span className="close-button" onClick={onClose}>
             <IoCloseCircle style={{margin:'10px 5px 5px 5px'}}/>
           </span>{" "}
            <div className="logistic-header">
                 <h5>Item Description</h5>
             </div>
         </div>
        <div className="logistics-flex">
          <div className="form-group">
            <input
              type="text"
              placeholder="Tell us about your item"
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  itemDescription: e.target.value,
                })
              }
              value={formData.itemDescription}
            />
          </div>
          <div className="form-group">
            <input
              type="number"
              placeholder="Item Weight (Kg)"
              value={formData.itemWeightInKg}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  itemWeightInKg: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="logistic-header">
          <h4>Delivery Details</h4>
        </div>
        <div className="logistics-flex">
          <div className="form-group">
            <select
              value={pickuplocation}
              onChange={(chg) => pickuplocationChanged(chg.target.value)}
            >
              <option>{pickup}</option>
              <option>Pick-Up from My Home</option>
              <option>Drop-Off at Company Location</option>
            </select>
          </div>
          <div className="form-group">
            <select
              value={dropOfflocation}
              onChange={(chg) => dropOfflocationChanged(chg.target.value)}
            >
              <option>{dropOff}</option>
              <option>Door step Delivery</option>
              <option>Pick-Up at Company Location</option>
            </select>
          </div>
          {pickuplocation === "Pick-Up from My Home" ? (
            <div className="form-group">
              <input
                type="text"
                placeholder="Pick up Address"
              value={formData.pickUpLocation}
                onChange={(e) =>
                  formDataChanged({
                    ...formData,
                    pickUpLocation: e.target.value,
                  })
                }
              />
            </div>
          ) : (
            <div className="form-group">
              <select
                onChange={(e) =>
                  formDataChanged({
                    ...formData,
                    pickUpLocation: e.target.value,
                  })
                }
                value={formData.pickUpLocation}
              >
                <option>--Select Location--</option>
                <option>Lagos</option>
                <option>Benue</option>
              </select>
            </div>
          )}
          {dropOfflocation === "Door step Delivery" ? (
            <div className="form-group">
              <input
                type="text"
                placeholder="Delivery Address"
              value={formData.deliveryLocation}
                onChange={(e) =>
                  formDataChanged({
                    ...formData,
                    deliveryLocation: e.target.value,
                  })
                }
              />
            </div>
          ) : (
            <div className="form-group">
              <select
                onChange={(e) =>
                  formDataChanged({
                    ...formData,
                    deliveryLocation: e.target.value,
                  })
                }
                value={formData.deliveryLocation}
              >
                <option>--Select Delivery Location--</option>
                <option>Enugu</option>
                <option>Abuja</option>
              </select>
            </div>
          )}
        </div>
        <div className="logistic-header">
          <h4>Sender & Receiver</h4>
        </div>
        <div className="logistics-flex">
          <div className="form-group">
            <input
              type="text"
              placeholder="Sender fullname"
              value={formData.fullname}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  fullname: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              placeholder="Receiver fullname"
              value={formData.receiverName}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  receiverName: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <input
              type="txt"
              placeholder="Sender phone"
              value={formData.phone}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  phone: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              placeholder="Receiver phone"
              value={formData.receiverPhone}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  receiverPhone: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              placeholder="Sender Email"
              value={formData.email}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  email: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              placeholder="Receiver Email"
              value={formData.receiverEmail}
              onChange={(e) =>
                formDataChanged({
                  ...formData,
                  receiverEmail: e.target.value,
                })
              }
            />
          </div>
        </div>
        <button className="submit-button" onClick={() => bookLogistic()}>
          Submit request
        </button>
      </div>
    </div>
  );
}

export default NewDeliveryForm;
