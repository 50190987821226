import React, { useEffect, useState } from "react";
import "./seatselection.css";
import { createTrip } from "../../service/trips";
import { formatDate, gotoDashboard } from "../../service/general";
import { IoMdCloseCircle } from "react-icons/io";
import {
  bookSeat,
  fetchAvailableSeats,
  fetchSeatDetails,
} from "../../service/routes";

const SeatSelection = ({
  closeSeatSelection,
  bookingData,
  additionalInfo,
  busType,
}) => {
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [paymentMode, setPaymentMode] = useState("--Select Payment Mode--");
  
  const [lastSelected, lastSelectedChanged] = useState(0);
  const [bookedSeats, setBookedSeats] = useState([]);

  useEffect(() => {
    if (bookingData?.routeId) {
      const loadSeatDetails = async () => {
        try {
          const seatDetails = await fetchAvailableSeats(
            bookingData.routeId,
            formatDate(bookingData.departureDate)
          );
          setBookedSeats(seatDetails);
        } catch (error) {
          console.error("Error fetching seat details", error);
        }
      };
      loadSeatDetails();
    } else {
      console.error("Route ID is not available");
    }
  }, [bookingData?.routeId]);

  const seatLayout =
    busType === "Bus"
      ? [
          ["driver", null, "1", "2"],
          ["3", "4", "5", null],
          ["6", "7", null, "8"],
          ["9", "10", null, "11"],
          ["12", "13", "14", "15"],
        ]
      : [
          ["driver", null, "1"],
          ["2", "3", "4"],
          ["5", "6", "7"],
        ];
  // const toggleSeatSelection = (seatNumber) => {
  //   if (seatNumber === lastSelected) {
  //     setSelectedSeats([]);
  //     lastSelectedChanged(0);
  //   } else {
  //     setSelectedSeats([seatNumber]);
  //     lastSelectedChanged(seatNumber);
  //   }
  // };

  const toggleSeatSelection = (seatNumber) => {
    if (!bookedSeats.includes(seatNumber)) {
      if (seatNumber === lastSelected) {
        setSelectedSeats([]);
        lastSelectedChanged(0);
      } else {
        setSelectedSeats([seatNumber]);
        lastSelectedChanged(seatNumber);
      }
    }
  };

  const handleContinue = async () => {
    //Update booking data
    bookingData.seatNumber = lastSelected;
    bookingData.paymentMode = paymentMode;
    bookingData.fullName=additionalInfo.fullName;
    bookingData.email=additionalInfo.email;
    bookingData.phone=additionalInfo.phone;
    bookingData.nextOfKinName=additionalInfo.nextOfKinName;
    bookingData.nextOfKinPhone=additionalInfo.nextOfKinPhone; 
    bookingData.pickupOption=additionalInfo.pickupOption;
    const response = await createTrip(bookingData);
    if (response.status === 200) {
    alert("You have successfully booked a trip with Tramas NG");
      closeSeatSelection();
      gotoDashboard();
      if (response.data.paymentType === "Online") {
        window.open(response.data.paymentlink);
      }
    } else {
      //Flag Error
      console.log(response.data);
    }
  };

  return (
    <div className="seat-selection-container">
      <div className="seat-selection-header">
        <h2>Select Seat(s)</h2>
        <span
          style={{ fontSize: "40px" }}
          className="close-btn"
          onClick={closeSeatSelection}
        >
          <IoMdCloseCircle />
        </span>
      </div>
      <div className="seat-selection-guide">
        <div>
          <span className="seat selected"></span>Selected Seat
        </div>
        <div>
          <span className="seat available"></span>Available Seat
        </div>
        <div>
          <span className="seat booked"></span>Booked Seat
        </div>
      </div>
      <div className="seat-map">
        {seatLayout.map((row, rowIndex) => (
          <div key={rowIndex} className="seat-row">
            {" "}
            {row.map((seat, seatIndex) => (
              <div
                key={seatIndex}
                className={`seat ${
                  seat
                    ? seat === "driver"
                      ? "driver"
                      : bookedSeats.includes(seat)
                      ? "booked"
                      : selectedSeats.includes(seat)
                      ? "selected"
                      : "available"
                    : "spacer"
                }`}
                onClick={() =>
                  seat && seat !== "driver" && toggleSeatSelection(seat)
                }
              >
                {" "}
                {seat === "driver" ? (
                  <img
                    src="https://media.istockphoto.com/id/1206795553/vector/vector-drive-symbol-icon-design.jpg?s=612x612&w=0&k=20&c=2SFwRpNCaEWZWMIFG2kTTOQdY8XfHJHIc6laJtAHUa0="
                    alt="Driver"
                  />
                ) : (
                  seat
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
<br></br>
<div style={{marginLeft:'20px', marginRight:'20px', border:'1px solid #ccc',paddingRight:'10px'}}>
      <select style={{padding:'20px'}} className="modal-input" onChange={(e)=>setPaymentMode(e.target.value)}>
        <option value="">--Select Payment Mode--</option>
        <option value="">Cash Payment (@ Station)</option>
        <option value="">Online (Raven Pay)</option>
      </select>

</div>

      <button className="continue-btn" onClick={handleContinue}>
        Proceed to Payment
      </button>
    </div>
  );
};
export default SeatSelection;
