import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './sidebar.css';
import { logout } from '../../service/users';

const Sidebar = () => {

  const navigate = useNavigate();

  const handleLogout = () => {
    // localStorage.clear();
    logout();
    navigate('/'); // Navigate to the home page
  };


  return (
    <div className="sidebar">
      <h2>TRAMAS</h2>
      <nav>
        <ul>
          <li><Link to="/dashboard/profile">Profile</Link></li>
          <li><Link to="/dashboard/trips">Trips</Link></li>
          <li><Link to="/dashboard/storage">Storage</Link></li>
          <li><Link to="/dashboard/logistics">Logistics</Link></li>
          <li><Link to="/dashboard/changepassword">Change Password</Link></li>
          <li><button onClick={handleLogout} className="logout-button">Logout</button></li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
