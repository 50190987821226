import { Route, Routes } from "react-router";
import "./App.css";
import Homepage from "./components/homepage/homepage";
import Dashboard from "./components/dashboard/dashboard";
import Signup from "./components/login/signup";
import Login from "./components/login/login";
import Selection from "./components/booking/selection";
import About from "./components/about/about";
import Logistics from "./components/services/logistics";
import Storage from "./components/services/storage";
import AdditionalInfo from "./components/booking/additionalinfo";
import AdminDashboard from "./components/admindashboard/admindashboard";
import Validation from "./components/login/validation";
import Profile from "./components/dashboard/profile";
import Trips from "./components/dashboard/trips";
import ChangePassword from "./components/dashboard/changepassword";
import ProtectedRoute from "./components/protectedRoute";
import AdminProtectedRoute from "./components/adminProtectedRoute";
import VehicleInventory from "./components/admindashboard/vehicleinventory";
import AdminTrip from "./components/admindashboard/admintrip";
import AdminStorage from "./components/admindashboard/adminstorage";
import AdminLogistics from "./components/admindashboard/adminlogistics";
import AdminRouteInventory from "./components/admindashboard/routeinventory/adminrouteinventory";
import JourneyInventory from "./components/admindashboard/journeyinventory";
// import AdminRouteInventory from "./components/admindashboard/adminrouteinventory";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />
      {/* <Route path="/admindashboard" element={<AdminDashboard />} /> */}
      {/* <Route path="/admindashboard" element={<AdminProtectedRoute><AdminDashboard/></AdminProtectedRoute>} /> */}
      <Route path="/admindashboard/*" element={<AdminDashboard />}>
        <Route path="profile" element={<AdminProtectedRoute><Profile/></AdminProtectedRoute>} />
        <Route path="vehicles" element={<AdminProtectedRoute><VehicleInventory/></AdminProtectedRoute>} />
        <Route path="passengers" element={<AdminProtectedRoute><AdminTrip/></AdminProtectedRoute>} />
        <Route path="storage" element={<AdminProtectedRoute><AdminStorage/></AdminProtectedRoute>} />
        <Route path="logistics" element={<AdminProtectedRoute><AdminLogistics/></AdminProtectedRoute>} />
        <Route path="routes" element={<AdminProtectedRoute><AdminRouteInventory/></AdminProtectedRoute>} />
        <Route path="journey" element={<AdminProtectedRoute><JourneyInventory/></AdminProtectedRoute>} />
        <Route path="changepassword" element={<AdminProtectedRoute><ChangePassword/></AdminProtectedRoute>} />
        {/* <Route index element={<Profile />} /> */}
      </Route>
      <Route path="/about" element={<About></About>}></Route>
      <Route path="/selection" element={<Selection></Selection>}></Route>
      <Route
        path="/additionalinfo"
        element={<AdditionalInfo></AdditionalInfo>}
      ></Route>
      <Route path="/validation" element={<Validation></Validation>}></Route>
      <Route path="/dashboard/*" element={<Dashboard />}>
        <Route path="profile" element={<ProtectedRoute><Profile/></ProtectedRoute>} />
        <Route path="trips" element={<ProtectedRoute><Trips/></ProtectedRoute>} />
        <Route path="changepassword" element={<ProtectedRoute><ChangePassword/></ProtectedRoute>} />
        {/* <Route index element={<Profile />} /> */}
      </Route>
      <Route path="storage" element={<Storage/>} />
      <Route path="logistics" element={<Logistics/>} />
    </Routes>
  );
}

export default App;