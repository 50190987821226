import React, { useState, useEffect } from 'react';
import './slideshow.css';
import busImage1 from '../../images/tramascourier1.jpeg';
import busImage2 from '../../images/tramascourier2.jpeg'; 
import busImage3 from '../../images/courier2.jpeg'; 
import busImage4 from '../../images/courier3.jpeg';
import busImage7 from '../../images/tramascourier6.jpeg'; 
import busImage from '../../images/courier5.jpeg';  
import busImage5 from '../../images/tramascourier7.jpeg'; 
import busImage6 from '../../images/tramascourier3.jpeg'; 
import busImage8 from '../../images/tramascourier4.jpeg';
import busImage9 from '../../images/tramascourier5.jpeg'; 



const SlideShow = () => {
  const images = [ busImage, busImage1, busImage2, busImage3, busImage4, busImage5, busImage6, busImage7, busImage8, busImage9];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); 

    return () => clearInterval(interval); 
  }, [images.length]);

  return (
    <div className="expertise-container">
      <div className="expertise-text">
        <h3>Your Trusted Partner</h3>
        <h1>TRAMAS COURIER AND LOGISTICS SERVICES</h1>
        <p>
          We are committed to delivering top-notch courier and logistics services across Nigeria.
        </p>
        <p>
          With a robust network, advanced tracking systems, and a dedicated team, you can trust us to handle your deliveries with precision and care.
        </p>
      </div>
      <div className="expertise-image">
        <div
          className="slideshow"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <img key={index} src={image} alt={`Slide ${index + 1}`} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SlideShow;
