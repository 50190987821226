// apiService.js

import axios from "axios";
import { ROOT_URL } from "./base";
import { checkUnauthorisedError, gotoRoot } from "./general";
const BASE_URL = `${ROOT_URL}/users`;

export const getAuthHeaders = () => {
  const token = localStorage.getItem("token");
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

export const getAdmin=()=>{
  let email="admin@tramas.ng"//localStorage.getItem('email')
  let pass="98765"//localStorage.getItem('pass')
  return {email:email,pass:pass}
}


export const getCurrentUser = async (allowUnauth = false) => {
  try {
    let loggedIn = localStorage.getItem("isLoggedIn");
    if (loggedIn !== undefined && loggedIn === "true") {
      let email = localStorage.getItem("email");
      let response = await fetchProfileResponse(email);
      if (response.status === 200) {
        return response.data;
      }
    }
    return null;
  } catch (error) {
    if (allowUnauth) {
      return null;
    } else {
      return checkUnauthorisedError(error, null);
    }
  }
};

export const login = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/login`, data);
    const { email, role, token } = response.data;
    if (response.status === 200) {
      localStorage.setItem("email", email);
      localStorage.setItem("token", token);  // Store only the token
      localStorage.setItem("role", role);    // Store the role from response
      localStorage.setItem("isLoggedIn", true);
    }

    if (role === "admin") {
      return 201;
    } else if (role === "user") {
      return 200;
    } else {
      return 0;
    }
  } catch (error) {
    return checkUnauthorisedError(error, 0);
  }
};

export const logout = async () => {
  localStorage.setItem("token", "");
  localStorage.setItem("role", "");
  localStorage.setItem("isLoggedIn", false);
  gotoRoot();
};

export const signup = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/signup`, userData);
    
    if (response.status === 201 || response.status === 200) {
      const { email, role, token } = response.data;
      localStorage.setItem("email", email);
      localStorage.setItem("role", role);
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("token", token);
    }
    console.log("res.status", response.status);
    return response.status;
  } catch (error) {
    return checkUnauthorisedError(error, {
      status: 501,
      data: error.response?.data || "Invalid registration",
    });
  }
};

export const validateCode = async (userData) => {
  try {
    var response = await axios.post(`${BASE_URL}/validate-code`, userData);
    console.log("from validationCode function service res: ", response.data.status)
    if (response.data.isAdmin === true && response.data.status === 200) {
      return 201;
    } else if (response.data.isAdmin === false && response.data.status === 200) {
      return 200;
    } else {
      return 0;
    }
  } catch (error) {
    return checkUnauthorisedError(error, 0);
  }
};
//userData={email:'email'}
export const isVerified = async (userData) => {
  try {
    var response = await axios.post(`${BASE_URL}/isverified`, userData);
    if (response.status === 200) {
      return 200;
    } else {
      return 0;
    }
  } catch (error) {
    return checkUnauthorisedError(error, 0);
  }
};

export const fetchProfile = async (email) => {
  try {
    var response = await axios.get(`${BASE_URL}/fetch-profile?email=${email}`, {
      headers: getAuthHeaders(),
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return checkUnauthorisedError(error, "Invalid login details");
  }
};
export const fetchProfileResponse = async (email) => {
  try {
    var response = await axios.get(`${BASE_URL}/fetch-profile?email=${email}`, {
      headers: getAuthHeaders(),
    });
    return { data: response.data, status: 200 };
  } catch (error) {
    return checkUnauthorisedError(error, { data: "Invalid", status: 401 });
  }
};

// Update user profile
export const updateProfile = async (profileData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/update-profile`,
      profileData,
      {
        headers: getAuthHeaders(),
      }
    );
    return response.status;
  } catch (error) {
    return checkUnauthorisedError(error, { data: "Invalid", status: 401 });
  }
};

// Change password
export const changePassword = async (passwordData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/change-password`,
      passwordData,
      {
        headers: getAuthHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return checkUnauthorisedError(error, error.response.data);
  }
};

// Forgot password
export const forgotPassword = async (email) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/forgot-password?email=${email}`
    );
    return response.data;
  } catch (error) {
    return checkUnauthorisedError(error, error.response.data);
  }
};
