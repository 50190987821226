import React, { useEffect, useState } from "react";
import "./admin_new.css";
import { IoCloseCircle } from "react-icons/io5";
import { createTrip } from "../../service/trips";
import { fetchRoutes, fetchTravellingTo, fetchUniqueRoutes } from "../../service/routes";

function NewTrip({ onClose }) {
  const [bookingData, setBookingData] = useState({
    uid: "",
    email: "",
    fullname: "",
    gender: "",
    nextOfKinName: "",
    nextOfKinPhone: "",
    phone: "",
    from: "",
    destination: "",
    createdDate: new Date(),
    departureDate: new Date(),
    departureTime: "7:00 AM",
    returnDate: new Date(),
    pickUpLocation: "",
    passenger: "",
    seatNumber: 0,
    vehicleType: "",
    fare: 0,
    discount: 0,
    isOnlineBooking: false,
    paymentType: "",
    paymentRef: "",
    paymentStatus: "",
    checkedIn: Boolean,
    checkInTime: Date,
    arrivalDate: Date,
    hasArrived: false,
    arrivalRemarks: "",
    review: "",
    rating: "",
    vehicleNumber: "",
    driverName: "",
    attendant: "",
    bookingRef: "",
  });

  const [routes, setRoutes] = useState([]);
  const [filteredDestinations, setFilteredDestinations] = useState([]);

  useEffect(() => {
    const getRoutes = async () => {
      const fetchedRoutes = await fetchRoutes();
      const from =fetchUniqueRoutes(fetchedRoutes);
      setRoutes(from);
    };
    getRoutes();
  }, []);

  const handleFromChange = (e) => {
    const selectedFrom = e.target.value;
    setBookingData({ ...bookingData, from: selectedFrom });
    const destinations = fetchTravellingTo(routes, selectedFrom);
    setFilteredDestinations(destinations);
  };

  const handleSubmit = async () => {
    let result = await createTrip(bookingData);
    if (result.status === 200) {
      alert(result.data);
      setBookingData({
        uid: "",
        email: "",
        fullname: "",
        gender: "",
        nextOfKinName: "",
        nextOfKinPhone: "",
        phone: "",
        from: "",
        destination: "",
        createdDate: new Date(),
        departureDate: new Date(),
        departureTime: "7:00 AM",
        returnDate: new Date(),
        pickUpLocation: "",
        passenger: "",
        seatNumber: 0,
        vehicleType: "",
        fare: 0,
        discount: 0,
        isOnlineBooking: false,
        paymentType: "",
        paymentRef: "",
        paymentStatus: "",
        checkedIn: Boolean,
        checkInTime: Date,
        arrivalDate: Date,
        hasArrived: false,
        arrivalRemarks: "",
        review: "",
        rating: "",
        vehicleNumber: "",
        driverName: "",
        attendant: "",
        bookingRef: "",
      });
      setFilteredDestinations([]);
      onClose();
    } else {
      alert(result.data);
    }
  };
  return (
    <div className="admin-modal-overlay">
      <div className="admin-dialog-container">
        <div className="modal-header">
          <span className="close-button" onClick={onClose}>
            <IoCloseCircle style={{ margin: "10px 5px 5px 5px" }} />
          </span>{" "}
          <div className="logistic-header">
            <h5>New Passenger</h5>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="modal-row">
            <div className="modal-field">
              <select
                className="modal-input"
                onChange={handleFromChange}
                value={bookingData.from}
              >
                <option value="">--Select Station--</option>
                {routes.map((route) => (
                  <option key={route._id} value={route.travellingFrom}>
                    {route.travellingFrom}
                  </option>
                ))}
              </select>
            </div>

            <div className="modal-field">
              <select
                className="modal-input"
                onChange={(e) =>
                  setBookingData({
                    ...bookingData,
                    destination: e.target.value,
                  })
                }
                value={bookingData.destination}
              >
                <option value="">--Select Destination--</option>
                {filteredDestinations.map((destination, index) => (
                  <option key={index} value={destination}>
                    {destination}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="modal-row">
            <div className="modal-field">
              <input
                type="date"
                placeholder="Trip Date"
                name="name"
                value={bookingData.departureDate}
                onChange={(e) =>
                  setBookingData({
                    ...bookingData,
                    departureDate: e.target.value,
                  })
                }
                className="modal-input"
                required
              />
            </div>
            <div className="modal-field">
              <select
                className="modal-input"
                onChange={(e) =>
                  setBookingData({
                    ...bookingData,
                    departureTime: e.target.value,
                  })
                }
              >
                <option value="">--Select Time--</option>
                {routes.map((route) => (
                  <option key={route.uid} value={route.departureTime}>
                    {route.departureTime}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="logistic-header">
            <h5>Passenger Details</h5>
          </div>
          <br></br>
          <div className="modal-row">
            <div className="modal-field">
              <input
                type="text"
                placeholder="Fullname"
                value={bookingData.fullname}
                onChange={(e) =>
                  setBookingData({ ...bookingData, fullname: e.target.value })
                }
                className="modal-input"
                required
              />
            </div>
            <div className="modal-field">
              <input
                type="text"
                placeholder="Phone Number"
                value={bookingData.phone}
                onChange={(e) =>
                  setBookingData({ ...bookingData, phone: e.target.value })
                }
                className="modal-input"
                required
              />
            </div>
            <div className="modal-field">
              <select
                type="text"
                onChange={(e) =>
                  setBookingData({ ...bookingData, gender: e.target.value })
                }
                className="modal-input"
                required
              >
                <option>--Choose Gender--</option>
                <option>Male</option>
                <option>Female</option>
              </select>
            </div>
          </div>
          <div className="logistic-header">
            <h5>Payment/Seat Info</h5>
          </div>
          <br></br>
          <div className="modal-row">
            <div className="modal-field">
              <input
                type="number"
                placeholder="Seat Number"
                onChange={(e) =>
                  setBookingData({ ...bookingData, seatNumber: e.target.value })
                }
                className="modal-input"
                required
              />
            </div>
            <div className="modal-field">
              <select
                type="text"
                value={bookingData.vehicleType}
                onChange={(e) =>
                  setBookingData({
                    ...bookingData,
                    vehicleType: e.target.value,
                  })
                }
                className="modal-input"
                required
              >
                <option value="">--Select Vehicle--</option>
                {[...new Set(routes.map((route) => route.vehicleType))].map(
                  (vehicleType, index) => (
                    <option key={index} value={vehicleType}>
                      {vehicleType}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className="modal-field">
              <select
                type="text"
                value={bookingData.paymentType}
                onChange={(e) =>
                  setBookingData({
                    ...bookingData,
                    paymentType: e.target.value,
                  })
                }
                className="modal-input"
                required
              >
                <option>--Payment Mode--</option>
                <option>POS</option>
                <option>Bank Transfer</option>
                <option>Cash</option>
              </select>
            </div>
          </div>
          <div className="logistic-header">
            <h5>Next of Kin Details</h5>
          </div>
          <br></br>
          <div className="modal-row">
            <div className="modal-field">
              <input
                type="text"
                placeholder="Fullname"
                name="name"
                onChange={(e) =>
                  setBookingData({
                    ...bookingData,
                    nextOfKinName: e.target.value,
                  })
                }
                className="modal-input"
                required
              />
            </div>
            <div className="modal-field">
              <input
                type="text"
                placeholder="Phone Number"
                name="name"
                onChange={(e) =>
                  setBookingData({
                    ...bookingData,
                    nextOfKinPhone: e.target.value,
                  })
                }
                className="modal-input"
                required
              />
            </div>
          </div>
          <br></br>
          <button type="submit" className="admin-modal-button">
            Book
          </button>
        </form>
      </div>
    </div>
  );
}

export default NewTrip;
