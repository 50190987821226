import React, { useState, useEffect } from 'react';
import './slideshow.css';
import busImage1 from '../../images/warehouse.jpeg'; 
import busImage2 from '../../images/moving.jpeg'; 
import busImage3 from '../../images/moving2.jpeg'; 
import busImage4 from '../../images/moving3.jpeg';  
import busImage5 from '../../images/moving4.jpeg'; 
import busImage6 from '../../images/tramascourier2.jpeg';  
import busImage7 from '../../images/moving6.jpeg'; 
import busImage8 from '../../images/moving2.jpeg';  

const StorageSlide = () => {
  const images = [busImage1, busImage2, busImage3, busImage4, busImage5, busImage6, busImage7, busImage8];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); 

    return () => clearInterval(interval); 
  }, [images.length]);

  return (
    <div className="expertise-container">
      <div className="expertise-text">
        <h3>Your Trusted Partner</h3>
        <h1>TRAMAS MOVING AND STORAGE SERVICES</h1>
        <p>
          We are dedicated to providing top-tier moving and storage services across Nigeria.
        </p>
        <p>
          With our extensive network, secure storage solutions, and professional team, you can trust us to manage your moving and storage needs with care and efficiency.
        </p>
      </div>
      <div className="expertise-image">
        <div
          className="slideshow"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <img key={index} src={image} alt={`Slide ${index + 1}`} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StorageSlide;
