import React, { useEffect, useRef, useState } from "react";
import "./bookingcomp.css";
import "./addinfo.css";
import { MdDateRange } from "react-icons/md";
import { FaRightLeft } from "react-icons/fa6";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { createTrip, fetchTripStatus } from "../../service/trips";
import { getCurrentUser } from "../../service/users";
import {
  fetchRoutes,
  fetchTravellingTo,
  fetchUniqueRoutes,
} from "../../service/routes";

const Bookingcomp = () => {
  const [loggedInUser, loggedInUserChanged] = useState({});
  const ref_depart = useRef();
  const ref_arrive = useRef();
  const [tab, tabChanged] = useState("seat");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [bookingStatus, bookingStatusChanged] = useState({
    ref: "",
    email: "",
  });
  const [oneWay, oneWayChanged] = useState(false);
  const [bookingData, setBookingData] = useState({
    from: "",
    to: "",
    departureDate: "",
    departureTime: "7:00 AM",
    returnDate: "",
    returnTime: "7:00 AM",
    travellers: "",
    vehicleType: "",
    isCharter: false,
  });

  const [routes, setRoutes] = useState([]);
  const [routesMain, setRoutesMain] = useState([]);
  const [filteredDestinations, setFilteredDestinations] = useState([]);

  useEffect(() => {
    const getRoutes = async () => {
      const fetchedRoutes = await fetchRoutes();
      const from = fetchUniqueRoutes(fetchedRoutes);
      setRoutes(from);
      setRoutesMain(fetchedRoutes);
    };
    getRoutes();
  }, []);

  const handleFromChange = (e) => {
    const selectedFrom = e.target.value;
    setBookingData({ ...bookingData, from: selectedFrom });
    const destinations = fetchTravellingTo(routesMain, selectedFrom);
    setFilteredDestinations(destinations);
  };

  const handleCheckboxChange = (e) => {
    setBookingData({
      ...bookingData,
      isCharter: e.target.checked,
    });
  };

  const navigate = useNavigate();

  const handleProceedClick = async () => {
    if (bookingData.from && bookingData.to && bookingData.departureDate) {
      if(bookingData.isCharter){
        const response = await createTrip(bookingData);
        if (response.status === 200) {
          navigate("/dashboard/profile"); // Navigate to the profile page
        } else {
          alert("Failed to create the trip. Please try again.");
        }
      }
      else{
        setFormSubmitted(true);
      }
    } else {
      alert("Please fill in all required fields.");
    }
  };

  useEffect(() => {
    const refreshUser = async () => {
      const data = await getCurrentUser(true);
      loggedInUserChanged(data);
    };
    refreshUser();
  }, []);

  if (formSubmitted) {
    return (
      <AdditionalInfo
        onFormSubmit={(additionalInfo) =>
          navigate("/selection", { state: { bookingData, additionalInfo } })
        }
        loggedInUser={loggedInUser}
      />
    );
  }

  return (
    <div className="header-container">
      <div className="header-main">
        <div className="header-flex">
          <div
            className={
              tab === "seat"
                ? "header-tab header-tab-active"
                : "header-tab header-tab-inactive"
            }
            onClick={() => tabChanged("seat")}
          >
            <span className="tab-text mobile-inactive">Book a seat</span>
          </div>
          <div
            className={
              tab === "status"
                ? "header-tab header-tab-active"
                : "header-tab header-tab-inactive"
            }
            onClick={() => tabChanged("status")}
          >
            <span className="tab-text">Booking status</span>
          </div>
        </div>
      </div>

      <div className="header-container-main">
        {tab !== "status" && (
          <>
            <h3 className="header-title">
              {tab === "bus"
                ? "Let us manage your next hire"
                : "Let us manage your next travel"}
            </h3>
            <p className="header-subtitle">Arriving safely is our goal</p>
            <div className="trip-type">
              <input
                id="one"
                type="radio"
                name="trip"
                value={oneWay}
                onChange={(chg) => oneWayChanged(chg.target.value)}
              />
              <label htmlFor="one">One-way</label>
              <input
                id="two"
                type="radio"
                name="trip"
                style={{ marginLeft: "10px" }}
                value={!oneWay}
                onChange={(chg) => oneWayChanged(!chg.target.value)}
              />
              <label htmlFor="two">Round Trip</label>
            </div>
          </>
        )}

        {tab === "seat" && (
          <div className="header-container-main-2" style={{ width: "90%" }}>
            <div className="destination-date">
              <p className="trip-detail-header">Destination</p>
              <div className="simple-flex">
                <select
                  className="modal-input"
                  onChange={handleFromChange}
                  value={bookingData.from}
                >
                  <option value="">--Select Station--</option>
                  {routes.map((route) => (
                    <option key={route} value={route}>
                      {route}
                    </option>
                  ))}
                </select>
                <div className="iconCircle">
                  <FaRightLeft />
                </div>
                <select
                  className="modal-input"
                  onChange={(e) =>
                    setBookingData({
                      ...bookingData,
                      to: e.target.value,
                    })
                  }
                  value={bookingData.to}
                >
                  <option value="">--Select Destination--</option>
                  {filteredDestinations.map((destination, index) => (
                    <option key={index} value={destination}>
                      {destination}
                    </option>
                  ))}
                </select>
              </div>
              <p className="trip-detail-header">Date(s)</p>
              <div className="simple-flex">
                <input
                  placeholder="Departure Date"
                  ref={ref_depart}
                  onChange={(e) =>
                    setBookingData({
                      ...bookingData,
                      departureDate: e.target.value,
                    })
                  }
                  onFocus={() => (ref_depart.current.type = "date")}
                  onBlur={() => (ref_depart.current.type = "text")}
                />
                {!oneWay && (
                  <>
                    <div className="iconCircle">
                      <MdDateRange />
                    </div>
                    <input
                      placeholder="Return Date"
                      ref={ref_arrive}
                      onChange={(e) =>
                        setBookingData({
                          ...bookingData,
                          returnDate: e.target.value,
                        })
                      }
                      onFocus={() => (ref_arrive.current.type = "date")}
                      onBlur={() => (ref_arrive.current.type = "text")}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="checkbox-label redx">
              <label className="label-bus" htmlFor="chater">
                Charter vehicle?
              </label> &nbsp; &nbsp;
              <input
                  id="chater"
                  type="checkbox"
                  checked={bookingData.isCharter}
                  onChange={handleCheckboxChange}
                />
            </div>
            {!bookingData.isCharter && (
              <div>
                <p className="trip-detail-header">Other Info</p>
                <div className="simple-flex">
                  <select
                    onChange={(e) =>
                      setBookingData({
                        ...bookingData,
                        travellers: e.target.value,
                      })
                    }
                  >
                    <option>--Select Passenger--</option>
                    <option>Adult</option>
                    <option>Child (under 13 years)</option>
                  </select>
                  <div className="iconCircle">
                    <BsFillPersonVcardFill />
                  </div>
                </div>
              </div>
            )}
            <button className="scheduleButton" onClick={handleProceedClick}>
              Proceed
            </button>
          </div>
        )}

        {tab === "status" && (
          <div className="header-container-main-2" style={{ width: "90%" }}>
            <p className="trip-detail-header">Registered Email/Phone Number</p>
            <input
              placeholder="Email/Phone"
              onChange={(e) =>
                bookingStatusChanged({
                  ...bookingStatus,
                  email: e.target.value,
                })
              }
            />
            <p className="trip-detail-header">Enter your reference code</p>
            <input
              placeholder="Reference Code"
              onChange={(e) =>
                bookingStatusChanged({
                  ...bookingStatus,
                  ref: e.target.value,
                })
              }
            />
            <button className="scheduleButton" onClick={() => fetchTripStatus()}>
              Check Status
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
const AdditionalInfo = ({ onFormSubmit, loggedInUser }) => {
  if (loggedInUser == null)
    loggedInUser = {
      firstname: "",
      lastname: "",
      phone: "",
      nextOfKinName: "",
      nextOfKinPhone: "",
    };
  const [fullName, setFullName] = useState(
    `${loggedInUser.firstname} ${loggedInUser.lastname}`
  );
  const [phone, setPhone] = useState(loggedInUser.phone);
  const [email, setEmail] = useState(loggedInUser.email);
  const [nextOfKinName, setNextOfKinName] = useState(
    loggedInUser.nextOfKinName
  );
  const [nextOfKinPhone, setNextOfKinPhone] = useState(
    loggedInUser.nextOfKinPhone
  );
  const [nextOfKinEmail, setNextOfKinEmail] = useState(
    loggedInUser.nextOfKinEmail
  );
  const [pickupOption, setPickupOption] = useState(null);
  const [lastOption, lastOptionChanged] = useState("off");
  const [address, setAddress] = useState({
    currentAddress: "",
    residentialAddress: "",
    state: "",
    city: "",
  });

  const handlePickupChange = (e) => {
    if (lastOption === "off") {
      setPickupOption("home");
      lastOptionChanged("on");
    } else {
      setPickupOption("other");
      lastOptionChanged("off");
    }
  };

  const handleFormSubmit = () => {
    const additionalInfo = {
      fullName,
      email,
      phone,
      nextOfKinName,
      nextOfKinPhone,
      nextOfKinEmail,
      pickupOption,
      address,
    };
    onFormSubmit(additionalInfo);
  };

  return (
    <div className="add-body">
      <section className="add-container">
        <header>Personal Information</header>
        <div className="add-form">
          <div className="column">
            <div className="input-boox">
              <label>Full Name</label>
              <input
                type="text"
                placeholder="Enter Your Full Name"
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            <div className="input-boox">
              <label>Email</label>
              <input
                type="text"
                placeholder="Enter Your Email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-boox">
              <label>Phone Number</label>
              <input
                type="tel"
                placeholder="Enter your phone number"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="column">
            <div className="input-boox">
              <label>Name of Next of Kin</label>
              <input
                type="text"
                placeholder="Enter Next of Kin Full Name"
                required
                value={nextOfKinName}
                onChange={(e) => setNextOfKinName(e.target.value)}
              />
            </div>
            <div className="input-boox">
              <label>Email of Next of Kin</label>
              <input
                type="text"
                placeholder="Enter Next of Kin Full Name"
                required
                value={nextOfKinEmail}
                onChange={(e) => setNextOfKinEmail(e.target.value)}
              />
            </div>
            <div className="input-boox">
              <label>Next of Kin Phone Number</label>
              <input
                type="tel"
                placeholder="Enter phone number of Next of Kin"
                required
                value={nextOfKinPhone}
                onChange={(e) => setNextOfKinPhone(e.target.value)}
              />
            </div>
          </div>
          <div>
            <div className="pick-at-home">
              <input
                id="home-pickup"
                type="checkbox"
                onChange={handlePickupChange}
              />
              <label for="home-pickup">Pickup At Home</label>
            </div>
            <b style={{ fontSize: "11px" }}>
              <i>Kindly note that pickup attracts additional charges</i>
            </b>
          </div>

          {pickupOption === "home" && (
            <div className="input-boox addresss">
              <label>Address</label>
              <input
                type="text"
                placeholder="Enter current address"
                required
                value={address.currentAddress}
                onChange={(e) =>
                  setAddress({ ...address, currentAddress: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Enter Residential address"
                required
                value={address.residentialAddress}
                onChange={(e) =>
                  setAddress({ ...address, residentialAddress: e.target.value })
                }
              />

              <input
                type="text"
                placeholder="Enter your city"
                required
                value={address.city}
                onChange={(e) =>
                  setAddress({ ...address, city: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Enter your state"
                required
                value={address.state}
                onChange={(e) =>
                  setAddress({ ...address, state: e.target.value })
                }
              />
            </div>
          )}

          <button onClick={() => handleFormSubmit()}>Submit</button>
        </div>
      </section>
    </div>
  );
};

export default Bookingcomp;
