import React, { useEffect, useState } from "react";
import "./selection.css";
import DashboardHeader from "../dashboard/dashboardheader";
import Footer from "../footer/footer";
import SeatSelection from "./seatselection";
import { useLocation } from "react-router-dom";
import { fetchFareCost } from "../../service/trips";
import {
  fetchVehicleImage,
  fetchRoutes,
  fetchTravellingInfo,
} from "../../service/routes";
import { BsCarFrontFill, BsPersonBadgeFill } from "react-icons/bs";
import { MdTimeToLeave } from "react-icons/md";

const Selection = () => {
  const { state } = useLocation();
  const { bookingData, additionalInfo } = state || {};
  const [selectedBusType, setSelectedBusType] = useState(null);
  const [travellingInfo, setTravellingInfo] = useState([]);

  useEffect(() => {
    console.log(bookingData)
    const getRoutes = async () => {
      const routes = await fetchRoutes();
      const route =  fetchTravellingInfo(
        routes,
        bookingData.to,
        bookingData.from
      );
      setTravellingInfo(route.travellingInfo);
    };
    getRoutes();
  }, []);

  const handleViewSeatsClick = (route) => {
    bookingData.routeId = route.vehicleNumber;
    bookingData.fare = route.price;
    bookingData.departureTime = route.departureTime;
    bookingData.vehicleType = route.vehicleType;
    setSelectedBusType(route.vehicleType);
  };
  const closeSeatSelection = () => {
    setSelectedBusType(null);
  };

  return (
    <div className="bus-type-container">
      <DashboardHeader />
      <br></br>
      <p>
        <b>Trip Information</b>
      </p>
      <br></br>
      <br></br>
      <h2
        style={{ color: "#0a1931", display: "flex", justifyContent: "center" }}
      >
        <BsCarFrontFill></BsCarFrontFill>&nbsp;&nbsp;<b>{bookingData?.from}</b>{" "}
        &nbsp;{">>>"}&nbsp; <b>{bookingData?.to}</b>
      </h2>
      <br></br>
      <h3>Select your bus</h3>
      {travellingInfo.map((route) => (
        <div className="bus-card">
          <div className="bus-image">
            <img
              src={require(`../../images/${fetchVehicleImage(
                route.vehicleType
              )}`)}
              alt={`${route.busType}`}
            />
          </div>
          <div className="bus-info">
            <h4>{route.vehicleType}</h4>
            <p>
              <MdTimeToLeave style={{ fontSize: "20px" }}></MdTimeToLeave>&nbsp;
              Departure Time: {route.departureTime}
            </p>
            <p>
              <BsPersonBadgeFill
                style={{ fontSize: "20px" }}
              ></BsPersonBadgeFill>
              &nbsp; Passenger Info: <b>{bookingData.travellers}</b>
            </p>
          </div>
          <div className="price-info">
            <span className="price">₦{route.price}</span>
            <button
              className="view-seats-btn"
              onClick={() => handleViewSeatsClick(route)}
            >
              View Seats
            </button>
          </div>
        </div>
      ))}
      {selectedBusType && (
        <div className="modal-overlay">
          <div className="modal-content">
            <SeatSelection
              closeSeatSelection={closeSeatSelection}
              bookingData={bookingData}
              // bookingData={{ ...bookingData, routeId }} // Include routeId
              additionalInfo={additionalInfo}
              busType={selectedBusType}
            />
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};
export default Selection;
