import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './sidebar';
import DashboardHeader from './dashboardheader';
import './dashboard.css';

function Dashboard() {
  return (
    <div>
      {/* <DashboardHeader /> */}
      <div className="dashboard">
        <Sidebar />
        <div className="main-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
