import React, { useState, useEffect } from "react";
import "./login.css";
import Signup from "../login/signup";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { login } from "../../service/users";

const Login = ({ onClose }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [showSignup, setShowSignup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSignupClick = () => {
    setShowSignup(true);
    document.getElementById("root").scrollIntoView({ behavior: "smooth" });
  };

  const closePopup = () => {
    setShowSignup(false);
    onClose();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmitClick = async (e) => {
    e.preventDefault();

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      setError("Please enter a valid email address");
      return;
    }

    // Validation
    if (!formData.email || !formData.password) {
      setError("All fields are required");
      return;
    }

    setError(""); // Clear any previous error

    try {
      const result = await login({
        email: formData.email,
        password: formData.password,
      });
      if (result === 201) {
        setTimeout(() => {
          navigate("/admindashboard/profile");
        }, 3000);
      } else if (result === 200) {
        navigate("/dashboard/profile");
      } else {
        setError("Error Logging In");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  };

  // Effect to clear error message after 3 seconds
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 3000);

      // Cleanup the timer on component unmount or when error changes
      return () => clearTimeout(timer);
    }
  }, [error]);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {!showSignup && (
        <div className="overlay-container">
          <div className="blur-bg-overlay"></div>
          <div className="login-container">
            <div className="welcome-section">
              <h2>Welcome Back</h2>
              <p>
                Please log in using your personal information to stay connected
                with us.
              </p>
            </div>
            <div className="login-form">
              <h3>LOGIN</h3>
              <Link className="close-btn" to="/">Close</Link>
              <form onSubmit={handleSubmitClick}>
                {error && <div className="error-message">{error}</div>}
                <div className="input-field">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-field password-field">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className="password-toggle-icon"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                <a href="/forgotpwrd" className="forgot-password">
                  Forgot password?
                </a>
                <button type="submit">Log In</button>
              </form>
              <p>
                Don't have an account?{" "}
                <a
                  href="#SIGN"
                  onClick={handleSignupClick}
                  className="signup-link"
                >
                  Signup
                </a>
              </p>
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default Login;
