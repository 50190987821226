import React, { useState } from 'react';
import './changepassword.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { changePassword } from '../../service/users';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);  // State for password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);  // State for confirm password visibility


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    setErrorMessage('');
    setSuccessMessage('');
  
    if (newPassword !== confirmPassword) {
      setErrorMessage("New password and confirm password do not match!");
      return;
    }
  
    const email = localStorage.getItem('email');
    try {
      const response = await changePassword({
        email,
        oldPassword,  // Use oldPassword for the current password
        newPassword,
        confirmPassword
      });
  
      if (response.success) {
        setSuccessMessage("Password changed successfully!");
      } else {
        setErrorMessage(response.message || "An error occurred. Please try again.");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
    }
  };
  

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="change-password-container">
      <form className="change-password-form" onSubmit={handleSubmit}>
        <h3>Change Password</h3>

        <div className="form-group">
          <label style={{ color: "white" }}>Old Password</label>
          <div className="password-field">
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="********"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <span onClick={togglePasswordVisibility} className="password-toggle-icon">
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>

        <div className="form-group">
          <label style={{ color: "white" }}>New Password</label>
          <div className="password-field">
            <input
            
              placeholder="********"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
           
          </div>
        </div>

        <div className="form-group">
          <label style={{ color: "white" }}>Confirm Password</label>
          <div className="password-field">
            <input
                type={showConfirmPassword ? 'text' : 'password'}
              placeholder="********"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <span onClick={toggleConfirmPasswordVisibility} className="password-toggle-icon">
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>

        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}

        <button type="submit" className="change-password-btn">Change Password</button>
      </form>
    </div>
  );
};

export default ChangePassword;