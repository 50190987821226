import React, { useState } from 'react';
import './reasons.css';
import { contactus } from '../../service/general';

const Reasons = () => {
  const [formData, formDataChanged] = useState({
    fullname:'',
    email: '',
    phone: '',
    title: '',
    message: ''
  });
  const contactUsAction=()=>{
    contactus(formData)
  }

  return (
    <div className="reasons-page" id='contact'>
      <div className="left-secction">
        <h4>Why Choose Us?</h4>
        <h1>We Create Opportunity to Reach Potential.</h1>
        <ul className="reasons-list">
          <li>Reliable and Efficient Transport Solutions</li>
          <li>Comprehensive Moving and Storage Services.</li>
          <li>Flexible Courier and Logistics Options.</li>
          <li>Dedicated to Customer Satisfaction.</li>
          <li>Innovative Logistics Management.</li>
          <li>Commitment to Safety and Security.</li>
        </ul>
      </div>

      <div className="right-secction">
        <form className="request-form">
        <h2 className="form-header">Contact Us</h2>
        <div className="form-group">
            <label>Name:</label>
            <input type="text" placeholder=" Enter Name:" 
                  onChange={(e) =>
                    formDataChanged({
                      ...formData,
                      fullname: e.target.value,
                    })
                  } required/>
          </div>
          <div className="form-group">
            <label>Email Address:</label>
            <input type="email" placeholder=" Enter Email Address:" 
                  onChange={(e) =>
                    formDataChanged({
                      ...formData,
                      email: e.target.value,
                    })
                  }/>
          </div>
          <div className="form-group">
            <label>Phone number:</label>
            <input type="tel" placeholder=" Enter Phone No:" 
                  onChange={(e) =>
                    formDataChanged({
                      ...formData,
                      phone: e.target.value,
                    })
                  } required/>
          </div>
          <div className="form-group">
            <label>Subject:</label>
            <input type="text" placeholder="Enter Sub:" 
                  onChange={(e) =>
                    formDataChanged({
                      ...formData,
                      title: e.target.value,
                    })
                  } required/>
          </div>
          <div className="text-area">
            <label>Message:</label>
            <textarea placeholder='Send a Message' 
                  onChange={(e) =>
                    formDataChanged({
                      ...formData,
                      message: e.target.value,
                    })
                  } />
          </div>
          <button className="submit-button" onClick={()=>contactUsAction()}>Submit request</button>
        </form>
      </div>
    </div>
  );
};

export default Reasons;
