import React, { useState } from "react";
import './addinfo.css';
import { createTrip } from "../../service/trips";

const AdditionalInfo = () => {
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [nextOfKinName, setNextOfKinName] = useState("");
  const [nextOfKinPhone, setNextOfKinPhone] = useState("");
  const [pickupOption, setPickupOption] = useState(null);
  const [address, setAddress] = useState({
    currentAddress: "",
    residentialAddress: "",
    state: "",
    city: ""
  });

  const handlePickupChange = (e) => {
    setPickupOption(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const additionalInfo = {
      fullName,
      phone,
      nextOfKinName,
      nextOfKinPhone,
      pickupOption,
      address
    };
    var data=additionalInfo;
    createTrip(data);
  };
  

  return (
    <div className="add-body">
      <section className="add-container">
        <header>Personal Information</header>
        <form className="add-form">
          <div className="column">
            <div className="input-boox">
              <label>Full Name</label>
              <input
                type="text"
                placeholder="Enter Your Full Name"
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            <div className="input-boox">
              <label>Phone Number</label>
              <input
                type="number"
                placeholder="Enter your phone number"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="column">
            <div className="input-boox">
              <label>Name of Next of Kin</label>
              <input
                type="text"
                placeholder="Enter Next of Kin Full Name"
                required
                value={nextOfKinName}
                onChange={(e) => setNextOfKinName(e.target.value)}
              />
            </div>
            <div className="input-boox">
              <label>Next of Kin Phone Number</label>
              <input
                type="number"
                placeholder="Enter phone number of Next of Kin"
                required
                value={nextOfKinPhone}
                onChange={(e) => setNextOfKinPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="pickup-boox">
            <h3>Pickup options: <i>Kindly note that pickup attracts additional charges</i></h3>
            <div className="pickup-option">
              <div className="pickup">
                <input
                  type="radio"
                  id="check-home"
                  name="pickup"
                  value="home"
                  checked={pickupOption === "home"}
                  onChange={handlePickupChange}
                />
                <label htmlFor="check-home">Yes, Pickup from home</label>
              </div>
              <div className="pickup">
                <input
                  type="radio"
                  id="check-other-means"
                  name="pickup"
                  value="other"
                  checked={pickupOption === "other"}
                  onChange={handlePickupChange}
                />
                <label htmlFor="check-other-means">No, other means</label>
              </div>
            </div>
          </div>
          
          {pickupOption === "home" && (
            <div className="input-boox addresss">
              <label>Address</label>
              <input
                type="text"
                placeholder="Enter current address"
                required
                value={address.currentAddress}
                onChange={(e) => setAddress({ ...address, currentAddress: e.target.value })}
              />
              <input
                type="text"
                placeholder="Enter Residential address"
                required
                value={address.residentialAddress}
                onChange={(e) => setAddress({ ...address, residentialAddress: e.target.value })}
              />
              <div className="column">
                <div className="select-boox">
                  <select
                    value={address.state}
                    onChange={(e) => setAddress({ ...address, state: e.target.value })}
                  >
                    <option hidden>State</option>
                    <option value="Abia">Abia</option>
                    <option value="Adamawa">Adamawa</option>
                    <option value="Anambra">Anambra</option>
                    <option value="Bauchi">Bauchi</option>
                    <option value="Bayelsa">Bayelsa</option>
                    <option value="Benue">Benue</option>
                    <option value="Borno">Borno</option>
                    <option value="Cross River">Cross River</option>
                    <option value="Delta">Delta</option>
                    <option value="Ebonyi">Ebonyi</option>
                    <option value="Edo">Edo</option>
                    <option value="Ekiti">Ekiti</option>
                    <option value="Enugu">Enugu</option>
                    <option value="FCT-Abuja">FCT-Abuja</option>
                    <option value="Gombe">Gombe</option>
                    <option value="Imo">Imo</option>
                    <option value="Jigawa">Jigawa</option>
                    <option value="Kaduna">Kaduna</option>
                    <option value="Kano">Kano</option>
                    <option value="Katsina">Katsina</option>
                    <option value="Kebbi">Kebbi</option>
                    <option value="Kogi">Kogi</option>
                    <option value="Kwara">Kwara</option>
                    <option value="Lagos">Lagos</option>
                    <option value="Nasarawa">Nasarawa</option>
                    <option value="Niger">Niger</option>
                    <option value="Ogun">Ogun</option>
                    <option value="Ondo">Ondo</option>
                    <option value="Osun">Osun</option>
                    <option value="Oyo">Oyo</option>
                    <option value="Plateau">Plateau</option>
                    <option value="Zamfara">Zamfara</option>
                    <option value="Rivers">Rivers</option>
                    <option value="Sokoto">Sokoto</option>
                    <option value="Taraba">Taraba</option>
                    <option value="Yobe">Yobe</option>
                  </select>
                </div>
                <input
                  type="text"
                  placeholder="Enter your city"
                  required
                  value={address.city}
                  onChange={(e) => setAddress({ ...address, city: e.target.value })}
                />
              </div>
            </div>
          )}

          <button onClick={()=>handleFormSubmit()}>Submit</button>
        </form>
      </section>
    </div>
  );
};

export default AdditionalInfo;
